declare var gsap : any;
declare var Swiper: any;

var animationAbout1 = gsap.timeline();
var sw = new Swiper("#aboutSwipper3", {
    slidesPerView: 3,
    spaceBetween: 60,  

    navigation: {
      nextEl: ".about-3 .about-3__content .swiper-button-next",
      prevEl: ".about-3 .about-3__content .swiper-button-prev",
    },
    speed: 1400,

    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },

    grid: {
      fill: 'row',
      rows: 2,
      columns: 3
    },

    breakpoints: {
      // 0: {
      //   slidesPerView: 1,
      // },
      // 320: {
      //   slidesPerView: 1.25,
      // },
      // 540: {
      //   slidesPerView: 2,
      // },
      0:{
        slidesPerView: 2,
        spaceBetween: 30,
        grid: {
          fill: 'row',
          rows: 2,
          columns: 2
        },
      },
      577:{
        slidesPerView: 2,
        spaceBetween: 30,
        grid: {
          fill: 'row',
          rows: 2,
          columns: 2
        },
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
        grid: {
          fill: 'row',
          rows: 2,
          columns: 3
        },
      },
      1440:{
        slidesPerView: 3,
        spaceBetween: 30, 
        grid: {
          fill: 'row',
          rows: 2,
          columns: 3
        },
      }
    }
  })
sw.initialSlide = 0;
sw.autoplay.stop();

function clear(){
    animationAbout1.clear();
}
function aniIn(){
    clear();
    var animationAbout1 = gsap.timeline();
    animationAbout1
    .to('#about-3 .about-3__title .title__name',{
        y: 0 ,  opacity: 1, duration: 0.2, 
    })
    .to('#about-3 .about-3__title .title__desc',{
        y: 0 ,  opacity: 1, duration: 0.2, 
    })
    .to('#about-3 .about-3__content',{
        y: 0 ,  opacity: 1, duration: 0.2, 
    })
    .to('#about-3 .about-3__content .swiper-slide img',{
        scale: 1,  
        opacity: 1, 
        stagger: {
            each: 0
        }
    })
	sw.autoplay.start();

}

function aniOut(){
    clear();
    // gsap.to('.scrollText', {
    //     duration: 0, y: '120px', opacity: 0
    // })
    var animationAbout1 = gsap.timeline();
    animationAbout1
    .to('#about-3 .about-3__title .title__name',{
        y: -50,
        opacity: 0,
        duration: 0, 
    })
    .to('#about-3 .about-3__title .title__desc',{
        y: -50,
        opacity: 0,
        duration: 0, 
    })
    .to('#about-3 .about-3__content',{
        y: 500,
        opacity: 0,
        duration: 0, 
    })
    .to('#about-3 .about-3__content .swiper-slide img',{
        scale: 0,
        opacity: 0,
        duration: 0, 
    })

    sw.autoplay.stop();
    sw.slideTo(0);
}

function aniClear(){
    clear();
    // gsap.to('.scrollText', {
    //     duration: 0, y: '120px', opacity: 0
    // })
    var animationAbout1 = gsap.timeline();
    animationAbout1
    .to('#about-3 .about-3__title .title__name',{
        y: 0,
        opacity: 1,
        duration: 0, 
    })
    .to('#about-3 .about-3__title .title__desc',{
        y: 0,
        opacity: 1,
        duration: 0, 
    })
    .to('#about-3 .about-3__content',{
        y: 0,
        opacity: 1,
        duration: 0, 
    })
    .to('#about-3 .about-3__content .swiper-slide img',{
        scale: 1,
        opacity: 1,
        duration: 0, 
    })
}

export default{
    aniIn,
    aniOut,
    aniClear
}