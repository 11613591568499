declare var gsap: any;
declare var Swiper: any;

let animationGsap = gsap.timeline();
var sw = new Swiper(".brn-index-services__wrapper--services .MyServices", {
	spaceBetween: 100,
	slidesPerView: 3,
	navigation: {
		nextEl: ".brn-index-services__wrapper--services .swiper-button-next",
		prevEl: ".brn-index-services__wrapper--services .swiper-button-prev",
	},
	speed: 1400,

	autoplay: {
		delay: 3500,
		disableOnInteraction: false,
	},

	breakpoints: {
		// 0: {
		//   slidesPerView: 1,
		// },
		// 320: {
		//   slidesPerView: 1.25,
		// },
		// 540: {
		//   slidesPerView: 2,
		// },
		// 768: {
		//   slidesPerView: 3,
		// }
		0: {
			spaceBetween: 10,
			slidesPerView: 1,
		},
		461: {
			spaceBetween: 20,
			slidesPerView: 2,
		},
		993: {
			spaceBetween: 20,
			slidesPerView: 3,
		},
		1440: {
			spaceBetween: 100,
			slidesPerView: 3,
		},
	},
});
sw.initialSlide = 0;
sw.autoplay.stop();

function clear() {
	animationGsap.clear();
}

function aniIn() {
	clear();

	animationGsap
		.to("#brn-index-services  .brn-index-services__wrapper--intro_title", {
			y: 0,
			opacity: 1,
			duration: 0.5,
		})
		.to(
			"#brn-index-services  .brn-index-services__wrapper--intro_decs",
			{
				x: 0,
				opacity: 1,
				duration: 0.5,
			},
			"<.4",
		)
		.to(
			"#brn-index-services  .brn-index-services__wrapper--services",
			{
				opacity: 1,
				duration: 0.3,
			},
			"<.2",
		)
		.to(
			"#brn-index-services  .swiper-slide",
			{
				x: 0,
				opacity: 1,
				duration: 0.5,
				stagger: 0.2,
			},
			"<.4",
		);

	sw.autoplay.start();
}

function aniOut() {
	clear();
	animationGsap
		.to("#brn-index-services  .brn-index-services__wrapper--intro_title", {
			y: -50,
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-services  .brn-index-services__wrapper--intro_decs", {
			x: 50,
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-services  .brn-index-services__wrapper--services", {
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-services  .swiper-slide", {
			x: 100,
			opacity: 0,
			duration: 0,
		});

	sw.autoplay.stop();
	sw.slideTo(0);
}


function aniClear() {
	clear();
	animationGsap
		.to("#brn-index-services  .brn-index-services__wrapper--intro_title", {
			y: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-services  .brn-index-services__wrapper--intro_decs", {
			x: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-services  .brn-index-services__wrapper--services", {
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-services  .swiper-slide", {
			x: 0,
			opacity: 1,
			duration: 0,
		});
}

export default {
	aniIn,
	aniOut,
	aniClear,
};
