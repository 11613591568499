declare var gsap : any;

let animationGsap = gsap.timeline();

function clear(){
    animationGsap.clear();
}

const aniIn = function(){
    clear();

    animationGsap
    .to('.contact1 .contact__title h3', {
        y: 0,
        opacity: 1,
        duration: .4
    }).to('.contact-form--right', {
        opacity: 1,
        duration: .6,
        x: 0
    }).to('#ContactFormImg .screen', {
        opacity: 1,
        duration: .3,
        scale: 1,
        transformOrigin: 'center'
    }, "<")
    .to('#ContactFormImg .logo', {
        opacity: 1,
        duration: 1,
        y: 0,
    })
    .to('#ContactFormImg .line path', {
        opacity: 1,
        stagger: {
            amount: 1,
            from: "end"
        }
    }, "<")
    .to('#ContactFormImg .setting .sbig', {
        opacity: 1,
        duration: 1,
    }, "<")
    .to('#ContactFormImg .setting .ssmall', {
        opacity: 1,
        duration: 1,
    }, "<")
    .to('#ContactFormImg .staff .staff01', {
        opacity: 1,
        duration: .3,
        x: 0,
    }, "<")
    .to('#ContactFormImg .telephone', {
        opacity: 1,
        duration: .3,
        scale: 1,
        transformOrigin: 'center'
    }, "<0.1")
    .to('#ContactFormImg .staff .staff02', {
        opacity: 1,
        duration: .3,
        y: 0,
    })
    .to('#ContactFormImg .time', {
        opacity: 1,
        duration: .3,
        rotateZ: 360,
        transformOrigin: 'center'
    }, "<0.1")
    .to('#ContactFormImg .staff .staff03', {
        opacity: 1,
        duration: .3,
        x: 0,
    })
    // .to('#ContactFormImg .time .timeWord', {
    //     duration: 4.5,
    //     rotateZ: 360,
    //     repeat: -1,
    //     transformOrigin: 'center'
    // }, "-=0.1")
    .to('#ContactFormImg .setting .sbig', {
        rotate: -359,
        duration: 6,
        repeat: -1,
        transformOrigin: 'center',
        ease: "linear",
    }, "2")
    .to('#ContactFormImg .setting .ssmall', {
        rotate: 359,
        duration: 6,
        repeat: -1,
        transformOrigin: 'center',
    }, "2.3")
}

const aniOut = function(){
    clear();
    animationGsap.to('.contact1 .contact__title h3', {
        opacity: 0,
        duration: 0,
        y: 120
    }).to('.contact-form--right', {
        opacity: 0,
        duration: 0,
        x: 150
    },"0").to('#ContactFormImg .screen', {
        opacity: 0,
        duration: 0,
        scale: 0,
        transformOrigin: 'center'
    },"0")
    .to('#ContactFormImg .logo', {
        opacity: 0,
        duration: 0,
        y: 120,
    },"0")
    .to('#ContactFormImg .line path', {
        opacity: 0,
        duration: 0,
    },"0")
    .to('#ContactFormImg .setting .sbig', {
        opacity: 0,
        duration: 0,
    },"0")
    .to('#ContactFormImg .setting .ssmall', {
        opacity: 0,
        duration: 0,
    },"0")
    .to('#ContactFormImg .setting .sbig', {
        rotate: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#ContactFormImg .setting .ssmall', {
        rotate: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#ContactFormImg .staff .staff01', {
        opacity: 0,
        duration: 0,
        x: 120,
    },"0")
    .to('#ContactFormImg .telephone', {
        opacity: 0,
        duration: 0,
        scale: 0,
        transformOrigin: 'center'
    },"0")
    .to('#ContactFormImg .staff .staff02', {
        opacity: 0,
        duration: 0,
        y: 100,
    },"0")
    .to('#ContactFormImg .time', {
        opacity: 0,
        duration: 0,
        rotateZ: 0,
        transformOrigin: 'center'
    },"0")
    // .to('#ContactFormImg .time .timeWord', {
    //     duration: 0,
    //     rotateZ: 0,
    //     transformOrigin: 'center'
    // }, "0")
    .to('#ContactFormImg .staff .staff03', {
        opacity: 0,
        duration: 0,
        x: -120,
    },"0")
}


const aniClear = function(){
    clear();
    animationGsap.to('.contact1 .contact__title h3', {
        opacity: 1,
        duration: 0,
        y: 0
    }).to('.contact-form--right', {
        opacity: 1,
        duration: 0,
        x: 0
    },"0").to('#ContactFormImg .screen', {
        opacity: 1,
        duration: 0,
        scale: 1,
        transformOrigin: 'center'
    },"0")
    .to('#ContactFormImg .logo', {
        opacity: 1,
        duration: 0,
        y: 0,
    },"0")
    .to('#ContactFormImg .line path', {
        opacity: 1,
        duration: 0,
    },"0")
    .to('#ContactFormImg .setting .sbig', {
        opacity: 1,
        duration: 0,
    },"0")
    .to('#ContactFormImg .setting .ssmall', {
        opacity: 1,
        duration: 0,
    },"0")
    .to('#ContactFormImg .setting .sbig', {
        rotate: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#ContactFormImg .setting .ssmall', {
        rotate: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#ContactFormImg .staff .staff01', {
        opacity: 1,
        duration: 0,
        x: 0,
    },"0")
    .to('#ContactFormImg .telephone', {
        opacity: 1,
        duration: 0,
        scale: 1,
        transformOrigin: 'center'
    },"0")
    .to('#ContactFormImg .staff .staff02', {
        opacity: 1,
        duration: 0,
        y: 0,
    },"0")
    .to('#ContactFormImg .time', {
        opacity: 1,
        duration: 0,
        rotateZ: 0,
        transformOrigin: 'center'
    },"0")
    // .to('#ContactFormImg .time .timeWord', {
    //     duration: 0,
    //     rotateZ: 0,
    //     transformOrigin: 'center'
    // }, "0")
    .to('#ContactFormImg .staff .staff03', {
        opacity: 1,
        duration: 0,
        x: 0,
    },"0")
}

export default{
    aniIn,
    aniOut,
    aniClear
}