declare var Swiper : any;

function autoBodyBrand (){
    let brand = document.querySelector('#newsDetailPage');
    if (brand){
        let headHtml = document.querySelector('head');
        headHtml.innerHTML += `<style>body, html{overflow-x:hidden;overflow-y:auto;height:auto}</style>`;
    
        let header = <HTMLElement>document.querySelector('.header');
        let spaceHeader = <HTMLElement> document.querySelector('.news-detail');
        let footer = <HTMLElement> document.getElementById('footer');

        if (spaceHeader && header){
            if (window.innerWidth >= 1280){
                spaceHeader.style.paddingTop = header.clientHeight + "px";
            }
            else{
                spaceHeader.style.paddingTop = "0px";
            }

            header.classList.add('scrollDown');

            let timeOutScroll: any;

            document.addEventListener('scroll', function(){
                clearTimeout(timeOutScroll);
                timeOutScroll = setTimeout(function(){
                    if (window.scrollY > 100){
                        header.style.boxShadow = "0 3px 6px rgba(0,0,0,.16)";
                    }
                    else{
                        header.style.boxShadow = "none";
                    }
                }, 200)
            })
        }

        if (footer){
            footer.classList.remove('full');
            footer.classList.add('relate');
        }
    }
}


export const newsDetail = function(){
    autoBodyBrand();
}