declare var gsap : any;
declare var anime : any;
let animationGsap = gsap.timeline();

function clear(){
    animationGsap.clear();
}

function aniIn(){
    clear();
    var loopLine = anime({
        targets: '#service-2 .service-2__content .service2-line-0',
        width: '100%', // -> from '28px' to '100%',
        easing: 'easeInOutQuad',
        direction: 'alternate',
        loop: true
    });
    animationGsap
    .to('#service-2 .service-2__title .title__name',{
        y: 0,
        opacity: 1,
        duration: .2,
    })
    .to('#service-2 .service-2__title .title__desc',{
        y: 0,
        opacity: 1,
        duration: .2,
    })
    .to('#service-2 .service-2__content',{
        y: 0,
        opacity: 1,
        duration: .2,
        
    })
    .to('#service-2 .service-2__content .brand-service__infor',{
        x: 0,
        opacity: 1,
        duration: .16,
    })
    .to('#service-2 .service-2__content .service2-nen-1',{
        y: 0,
        opacity: 1,
        duration: .16,
    }, "<")

    .to('#service-2 .service-2__content .service2-manhinh-1',{
        y: 0,
        scale: 1,
        opacity: 1,
        duration: .4,
    },)
    .to('#service-2 .service-2__content .service2-banphim-1',{
        x: 0,
        scale: 1,
        opacity: 1,
        duration: 0.4,
    },"<0.2")
    .to('#service-2 .service-2__content .service2-phone-1',{
        x: 0,
        scale: 1,
        opacity: 1,
        duration: .16,
    },)
    .to('#service-2 .service-2__content .service2-vongtron-1',{
        scale: 1,
        opacity: 1,
        stagger: {
            each: 0.3
        },
    },)
    .to('#service-2 .service-2__content .service2-themau-1',{
        scale: 1,
        x: 0,
        opacity: 1,
        transformOrigin: 'bottom',
        stagger: {
            each: 0.3
        },
    }, "<")
    .to('#service-2 .service-2__content .service2-people-1',{
        x: 0,
        opacity: 1,
        duration: 0.16,
    },)
    .to('#service-2 .service-2__content .service2-people-2',{
        x: 0,
        opacity: 1,
        duration: 0.16,
    },)

}
function aniOut(){
    clear();
    animationGsap
    .to('#service-2 .service-2__title .title__name',{
        y: -50,
        opacity: 0,
        duration: 0, 
        },)
    .to('#service-2 .service-2__title .title__desc',{
        y: 50,
        opacity: 0,
        duration: 0,
    },)
    .to('#service-2 .service-2__content',{
        y: 1000,
        opacity: 0,
        duration: 0,
    },)
    .to('#service-2 .service-2__content .brand-service__infor',{
        x: -1500,
        opacity: 0,
        duration: 0,
    }, "0")
    .to('#service-2 .service-2__content .service2-nen-1',{
        y: 2000,
        opacity: 0,
        duration: 0,
    },)
    .to('#service-2 .service-2__content .service2-manhinh-1',{
        y: -200,
        scale: 3,
        opacity: 0,
        duration: 0,
    },)
    .to('#service-2 .service-2__content .service2-banphim-1',{
        x: -100,
        scale: 2,
        opacity: 0,
        duration: 0,
    },)
    .to('#service-2 .service-2__content .service2-phone-1',{
        x: 150,
        scale: 0,
        opacity: 0,
        duration: 0,
    },)
    .to('#service-2 .service-2__content .service2-vongtron-1',{
        scale: 5,
        opacity: 0,
        duration: 0,
    },)
    .to('#service-2 .service-2__content .service2-themau-1',{
        scale: 2.2,
        x: -120,
        opacity: 0,
        duration: 0,
        transformOrigin: 'bottom'
    },)
    .to('#service-2 .service-2__content .service2-people-2',{
        x: 150,
        opacity: 0,
        duration: 0,
    },)
    .to('#service-2 .service-2__content .service2-people-1',{
        x: -100,
        opacity: 0,
        duration: 0,
    },)
}

function aniClear(){
    clear();
    animationGsap
    .to('#service-2 .service-2__title .title__name',{
        y: 0,
        opacity: 1,
        duration: 0, 
        },)
    .to('#service-2 .service-2__title .title__desc',{
        y: 0,
        opacity: 1,
        duration: 0,
    },)
    .to('#service-2 .service-2__content',{
        y: 0,
        opacity: 1,
        duration: 0,
    },)
    .to('#service-2 .service-2__content .brand-service__infor',{
        x: 0,
        opacity: 1,
        duration: 0,
    }, "0")
    .to('#service-2 .service-2__content .service2-nen-1',{
        y: 0,
        opacity: 1,
        duration: 0,
    },)
    .to('#service-2 .service-2__content .service2-manhinh-1',{
        y: 0,
        scale: 1,
        opacity: 1,
        duration: 0,
    },)
    .to('#service-2 .service-2__content .service2-banphim-1',{
        x: 0,
        scale: 1,
        opacity: 1,
        duration: 0,
    },)
    .to('#service-2 .service-2__content .service2-phone-1',{
        x: 0,
        scale: 1,
        opacity: 1,
        duration: 0,
    },)
    .to('#service-2 .service-2__content .service2-vongtron-1',{
        scale: 1,
        opacity: 1,
        duration: 0,
    },)
    .to('#service-2 .service-2__content .service2-themau-1',{
        scale: 1,
        x: 0,
        opacity: 1,
        duration: 0,
        transformOrigin: 'bottom'
    },)
    .to('#service-2 .service-2__content .service2-people-2',{
        x: 0,
        opacity: 1,
        duration: 0,
    })
    .to('#service-2 .service-2__content .service2-people-1',{
        x: 0,
        opacity: 1,
        duration: 0,
    })
}

export default{
    aniIn,
    aniOut,
    aniClear
}