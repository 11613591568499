declare var gsap: any;
declare var Swiper: any;

var animationAbout1 = gsap.timeline();
var sw = new Swiper("#aboutSwipper1", {
	spaceBetween: 16,
	slidesPerView: 4,

	navigation: {
		nextEl: ".about-1__content .nav .swiper-button-next",
		prevEl: ".about-1__content .nav .swiper-button-prev",
	},
	speed: 1400,

	autoplay: {
		delay: 3500,
		disableOnInteraction: false,
	},

	breakpoints: {
		// 0: {
		//   slidesPerView: 1,
		// },
		// 320: {
		//   slidesPerView: 1.25,
		// },
		0: {
			slidesPerView: 1,
			spaceBetween: 8,
		},
		577: {
			slidesPerView: 2,
			spaceBetween: 8,
		},
		769: {
			slidesPerView: 2.5,
			spaceBetween: 8,
		},
		1281: {
			slidesPerView: 3,
			spaceBetween: 16,
		},
		1441: {
			slidesPerView: 4,
			spaceBetween: 16,
		},
	},
});

sw.initialSlide = 0;
sw.autoplay.stop();

function clear() {
	animationAbout1.clear();
}

function aniIn() {
	clear();
	animationAbout1
		.to("#about-1 .about-1__title", {
			y: 0,
			opacity: 1,
			duration: 0.2,
		})
		.to("#about-1 .about-1__slide .swiper-slide", {
			y: 0,
			opcaty: 1,
			duration: 1,
			stagger: 0.3,
		});

	sw.autoplay.start();
}

function aniOut() {
	clear();
	animationAbout1
		.to("#about-1 .about-1__title", {
			y: 250,
			opacity: 0,
			duration: 0,
		})
		.to("#about-1 .about-1__slide .swiper-slide", {
			y: -600,
			opcaty: 0,
			duration: 0,
		});

    sw.autoplay.stop();
	sw.slideTo(0);
}

function aniClear() {
	clear();
	animationAbout1
		.to("#about-1 .about-1__title", {
			y: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#about-1 .about-1__slide .swiper-slide", {
			y: 0,
			opcaty: 1,
			duration: 0,
		});
}

export default {
	aniIn,
	aniOut,
	aniClear,
};
