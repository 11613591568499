declare var gsap : any;
let animationGsap = gsap.timeline();

function clear(){
    animationGsap.clear();
}

function aniIn(){
    clear();

    animationGsap
    .to('.contact2 .contact-left', {
        opacity: 1,
        duration: .16,
        x: 0
    })
    .to('.contact2 .contact__title h3', {
        y: 0,
        opacity: 1,
        duration: .4
    })
    .to('.contact2 .contact-info', {
        scale: 1,
        opacity: 1,
        duration: .16
    })
    .to('.contact2 .contact-info__item', {
        y: 0,
        opacity: 1,
        stagger: {
            each: 0.16,
            from: "start"
        }
    })
}
function aniOut(){
    clear();

    animationGsap
    .to('.contact2 .contact-left', {
        opacity: 0,
        duration: 0,
        x: -150
    })
    .to('.contact2 .contact__title h3', {
        opacity: 0,
        duration: 0,
        y: 120
    })
    .to('.contact2 .contact-info', {
        scale: 0,
        opacity: 0,
        duration: 0
    },"0")
    .to('.contact2 .contact-info__item', {
        y: 50,
        opacity: 0,
        duration: 0,
    },"0")
}

function aniClear(){
    clear();

    animationGsap
    .to('.contact2 .contact-left', {
        opacity: 1,
        duration: 0,
        x: 0
    })
    .to('.contact2 .contact__title h3', {
        opacity: 1,
        duration: 0,
        y: 0
    })
    .to('.contact2 .contact-info', {
        scale: 1,
        opacity: 1,
        duration: 0
    },"0")
    .to('.contact2 .contact-info__item', {
        y: 0,
        opacity: 1,
        duration: 0,
    },"0")
}
export default{
    aniIn,
    aniOut,
    aniClear
}