declare var gsap : any;
declare var anime : any;
let animationGsap = gsap.timeline();

function clear(){
    animationGsap.clear();
}
function aniIn(){
    clear();
    
    animationGsap
    .to('#service-3 .service-3__title .title__name',{
        y: 0,
        opacity: 1,
        duration: .2,
    })
    .to('#service-3 .service-3__title .title__desc',{
        y: 0,
        opacity: 1,
        duration: .2,
    })
    .to('#service-3 .service-3__content',{
        y: 0,
        opacity: 1,
        duration: .2,
    })
    .to('#service-3 .service-3__content .brand-service__infor',{
        x: 0,
        opacity: 1,
        duration: .16,
    })
    .to('#service-3 .service-3__content .service3-nen-1',{
        y: 0,
        opacity: 1,
        duration: 0.2,
    }, "<")
    .to('#service-3 .service-3__content .service3-loa-1',{
        scale: 1,
        opacity: 1,
        duration: 0.3,
    }, "<0.1")
    .to('#service-3 .service-3__content .service3-phone-1',{
        scale: 1,
        opacity: 1,
        duration: 0.16,
    }, "<0.2")
    .to('#service-3 .service-3__content .service3-people-1',{
        scale: 1,
        transformOrigin: 'center',
        opacity: 1,
        stagger: {
            each: 0.16
        },
    },)
    .to('#service-3 .service-3__content .service3-table-1',{
        scale: 1,
        rotate: 360,
        transformOrigin: 'center',
        opacity: 1,
        duration: 0.2,
    },)
    .to('#service-3 .service-3__content .service3-tag-1',{
        scale: 1,
        transformOrigin: 'top',
        opacity: 1,
        stagger: 0.3,

    }, "<")

    
}
function aniOut(){
    clear();
    animationGsap
    .to('#service-3 .service-3__title .title__name',{
        y: -50,
        opacity: 0,
        duration: 0, 
        }, )
    .to('#service-3 .service-3__title .title__desc',{
        y: 50,
        opacity: 0,
        duration: 0,
    },)
    .to('#service-3 .service-3__content',{
        y: 1000,
        opacity: 0,
        duration: 0,
    },)
    .to('#service-3 .service-3__content .service3-nen-1',{
        y: 1500,
        opacity: 0,
        duration: 0,
    },)
    .to('#service-3 .service-3__content .service3-loa-1',{
        scale: 2.2,
        opacity: 0,
        duration: 0,
    },)
    .to('#service-3 .service-3__content .service3-phone-1',{
        scale: 3,
        opacity: 0,
        duration: 0,
    },)
    .to('#service-3 .service-3__content .service3-people-1',{
        scale: 3,
        transformOrigin: 'center',
        opacity: 0,
        duration: 0,
    },)
    .to('#service-3 .service-3__content .service3-table-1',{
        scale: 0,
        rotate: 0,
        transformOrigin: 'center',
        opacity: 0,
        duration: 0,
    },)
    .to('#service-3 .service-3__content .service3-tag-1',{
        scale: 2.3,
        transformOrigin: 'top',
        opacity: 0,
        duration: 0,
    },)
    .to('#service-3 .service-3__content .brand-service__infor',{
        x: 150,
        opacity: 0,
        duration: 0,
    }, "0")
}

function aniClear(){
    clear();
    animationGsap
    .to('#service-3 .service-3__title .title__name',{
        y: 0,
        opacity: 1,
        duration: 0, 
        }, )
    .to('#service-3 .service-3__title .title__desc',{
        y: 0,
        opacity: 1,
        duration: 0,
    },)
    .to('#service-3 .service-3__content',{
        y: 0,
        opacity: 1,
        duration: 0,
    },)
    .to('#service-3 .service-3__content .service3-nen-1',{
        y: 0,
        opacity: 1,
        duration: 0,
    },)
    .to('#service-3 .service-3__content .service3-loa-1',{
        scale: 1,
        opacity: 1,
        duration: 0,
    },)
    .to('#service-3 .service-3__content .service3-phone-1',{
        scale: 1,
        opacity: 1,
        duration: 0,
    },)
    .to('#service-3 .service-3__content .service3-people-1',{
        scale: 1,
        transformOrigin: 'center',
        opacity: 1,
        duration: 0,
    },)
    .to('#service-3 .service-3__content .service3-table-1',{
        scale: 1,
        rotate: 0,
        transformOrigin: 'center',
        opacity: 1,
        duration: 0,
    },)
    .to('#service-3 .service-3__content .service3-tag-1',{
        scale: 1,
        transformOrigin: 'top',
        opacity: 1,
        duration: 0,
    },)
    .to('#service-3 .service-3__content .brand-service__infor',{
        x: 0,
        opacity: 1,
        duration: 0,
    }, "0")
}

export default{
    aniIn,
    aniOut,
    aniClear
}