declare var gsap: any;
declare var Parallax: any;
declare var Swiper: any;
declare var $: any;
// const Parallax = require('parallax-js')

// // ----- Services ----- //
// function swiperService() {
// 	const serviceSW = document.querySelector(
// 		".brn-index-services__wrapper--services .MyServices",
// 	);
// 	if (serviceSW) {
// 		var swiper = new Swiper(
// 			".brn-index-services__wrapper--services .MyServices",
// 			{
// 				spaceBetween: 100,
// 				slidesPerView: 3,
// 				navigation: {
// 					nextEl:
// 						".brn-index-services__wrapper--services .swiper-button-next",
// 					prevEl:
// 						".brn-index-services__wrapper--services .swiper-button-prev",
// 				},
// 				speed: 1400,

// 				autoplay: {
// 					delay: 3500,
// 					disableOnInteraction: false,
// 				},

// 				breakpoints: {
// 					// 0: {
// 					//   slidesPerView: 1,
// 					// },
// 					// 320: {
// 					//   slidesPerView: 1.25,
// 					// },
// 					// 540: {
// 					//   slidesPerView: 2,
// 					// },
// 					// 768: {
// 					//   slidesPerView: 3,
// 					// }
// 					0: {
// 						spaceBetween: 10,
// 						slidesPerView: 1,
// 					},
// 					461: {
// 						spaceBetween: 20,
// 						slidesPerView: 2,
// 					},
// 					993: {
// 						spaceBetween: 20,
// 						slidesPerView: 3,
// 					},
// 					1440: {
// 						spaceBetween: 100,
// 						slidesPerView: 3,
// 					},
// 				},
// 			},
// 		);
// 	}
// }

// // ----- Projects ----- //
// function swiperProject() {
// 	const projectSW = document.querySelector(
// 		".brn-index-project__wrapper--project .MyProjects",
// 	);
// 	if (projectSW) {
// 		var swiper = new Swiper(
// 			".brn-index-project__wrapper--project .MyProjects",
// 			{
// 				spaceBetween: 80,
// 				slidesPerView: 3,
// 				navigation: {
// 					nextEl:
// 						".brn-index-project__wrapper--project .swiper-button-next",
// 					prevEl:
// 						".brn-index-project__wrapper--project .swiper-button-prev",
// 				},
// 				speed: 1400,
// 				autoplay: {
// 					delay: 3500,
// 					disableOnInteraction: false,
// 				},
// 				breakpoints: {
// 					// 0: {
// 					//   slidesPerView: 1,
// 					// },
// 					// 320: {
// 					//   slidesPerView: 1.25,
// 					// },
// 					// 540: {
// 					//   slidesPerView: 2,
// 					// },
// 					// 768: {
// 					//   slidesPerView: 3,
// 					// }

// 					0: {
// 						spaceBetween: 10,
// 						slidesPerView: 1,
// 					},
// 					461: {
// 						spaceBetween: 20,
// 						slidesPerView: 2,
// 					},
// 					993: {
// 						spaceBetween: 20,
// 						slidesPerView: 3,
// 					},
// 					1440: {
// 						spaceBetween: 80,
// 						slidesPerView: 3,
// 					},
// 				},
// 			},
// 		);
// 	}
// }

// // ----- Client ----- //
// function swiperClient() {
// 	const clientSW = document.querySelector(
// 		".brn-index-client__wrapper--client__list .MyClient",
// 	);
// 	if (clientSW) {
// 		var swiper = new Swiper(clientSW, {
// 			spaceBetween: 10,
// 			slidesPerView: 1,
// 			pagination: {
// 				el:
// 					".brn-index-client__wrapper--client__list .swiper-pagination",
// 				dynamicBullets: true,
// 			},
// 			grabCursor: true,
// 			speed: 1400,
// 			autoplay: {
// 				delay: 3500,
// 				disableOnInteraction: false,
// 			},
// 		});
// 	}
// }

// function swiperClientLogo() {
// 	const clientlogoSW = document.querySelector(".MyClientLogo");
// 	if (clientlogoSW) {
// 		var swiper_1 = new Swiper(clientlogoSW, {
// 			spaceBetween: 30,
// 			slidesPerView: 0.66667,

// 			// direction: "vertical",
// 			grabCursor: true,
// 			speed: 1400,
// 			autoplay: {
// 				delay: 2500,
// 				disableOnInteraction: false,
// 			},

// 			breakpoints: {
// 				0: {
// 					slidesPerView: 0.66667,
// 					direction: "horizontal",
// 					autoplay: {
// 						delay: 2500,
// 					},
// 					loop: true,
// 				},
// 				992: {
// 					direction: "vertical",
// 					slidesPerView: 3,
// 					autoplay: {
// 						delay: 2500,
// 					},
// 					loop: false,
// 				},
// 			},
// 		});
// 	}
// }

// ----- All Project ----- //

// function disableScroll(){
//     const servicesPage = document.getElementById("brn-index-process")
//     if(servicesPage){
//         const blockProcess = servicesPage.querySelector(".brn-index-process__wrapper--process__list")
//         blockProcess.addEventListener("mouseover",()=>{
//             $('#fullpage').fullpage({
//                 autoScrolling: false,
//                 fitToSection: false
//             })
//         })
//     }
// }

export const index = function () {
	// service
	// swiperService();

	// project
	// swiperProject();
	// process
	activeProcess();
	// swiperProcess();
	// client
	// swiperClient();
	// swiperClientLogo();
};

// ----- Process ----- //
function activeProcess() {
	const processPage = document.getElementById("brn-index-process");
	if (processPage) {
		const listName = processPage.querySelectorAll("ol .items");
		listName.forEach(function (element) {
			const inforName = element.querySelector("ol .items-name");
			inforName.addEventListener("click", () => {
				const eleActive = processPage.querySelector("ol .items.active");
				element.classList.add("active");
				const listInforACtive = processPage.querySelector(
					"ol .items-infor.active",
				);
				const listInfor = element.querySelector(".items-infor");
				if (element.classList.value === "items active") {
					if (listInfor) {
						listInfor.classList.add("active");
					}
					// element.classList.remove("active")
				} else {
					if (listInfor) {
						listInfor.classList.remove("active");
					}
				}
				if (eleActive && listInforACtive) {
					eleActive.classList.remove("active");
					listInforACtive.classList.remove("active");
				}
			});
		});
	}
}
// function swiperProcess (){
//   const processPage = document.getElementById("brn-index-process")
//   if(processPage){
//       const ProcessSW = processPage.querySelector(".MyProcess")
//       if(ProcessSW){
//           var swiper = new Swiper(ProcessSW, {
//               direction: "vertical",
//               slidesPerView: "auto",
//               freeMode: true,
//               scrollbar: {
//               el: ".swiper-scrollbar",
//               },
//               mousewheel: true,
//               speed: 1400,
//               autoplay: {
//                 delay: 3500,
//                 disableOnInteraction: false,
//               },
//           })
//       }
//   }
// }
