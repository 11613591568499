declare var gsap : any;

function aniIn(){
    
    // gsap.from('.loading-logo', {
    //     duration: 2.5,
    //     opacity: 0,
    //     y: 500,
    // })
}


export default{
    aniIn,
    delay: 1000
}