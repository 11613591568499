declare var gsap : any;
declare var Power2 : any;

let animationGsap = gsap.timeline();

function clear (){
    animationGsap.clear();
}

function aniIn(){
    clear()

    animationGsap.to('#brn-index-contact .brn-index-contact__wrapper--contact__title', {
        y:0,
        opacity: 1,
        duration: .5,
        delay: .1
    }).to('#brn-index-contact .brn-index-contact__wrapper--contact__infor', {
        opacity: 1,
        duration: .5,
    },"<.4").to('#brn-index-contact .company_infor-items', {
        y:0,
        opacity: 1,
        duration: .5,
        stagger: 0.2 
    }).to('#brn-index-contact .brn-index-contact__wrapper--contact__logo', {
        y:0,
        opacity: 1,
        duration: .4,
    },"<.8")
    
}

function aniOut(){
    clear()
    animationGsap.to('#brn-index-contact .brn-index-contact__wrapper--contact__title', {
        y:100,
        opacity: 0,
        duration: 0,
    }).to('#brn-index-contact .brn-index-contact__wrapper--contact__infor', {
        opacity: 0,
        duration: 0,
    }).to('#brn-index-contact .company_infor-items', {
        y:100,
        opacity: 0,
        duration: 0,
    })
    .to('#brn-index-contact .brn-index-contact__wrapper--contact__logo', {
        y:100,
        opacity: 0,
        duration: 0,
    })
}


function aniClear(){
    clear()
    animationGsap.to('#brn-index-contact .brn-index-contact__wrapper--contact__title', {
        y:0,
        opacity: 1,
        duration: 0,
    }).to('#brn-index-contact .brn-index-contact__wrapper--contact__infor', {
        opacity: 1,
        duration: 0,
    }).to('#brn-index-contact .company_infor-items', {
        y:0,
        opacity: 1,
        duration: 0,
    })
    .to('#brn-index-contact .brn-index-contact__wrapper--contact__logo', {
        y:0,
        opacity: 1,
        duration: 0,
    })
}


export default{
    aniIn,
    aniOut,
    aniClear
}