declare var $ : any;
import animateFullpage from "./animateFullpage";

const rpWidth = 1280;
const rpHeight = 540;

// class Utils{
//     toArray(element:any){
//         return [].slice.call(element);
//     }
//     get(selector:any){
//         return document.querySelector(selector);
//     }
// }

function setup(){
    if (!document.getElementById('fullpage')){
        return;
    }

    let ready = false;
    let animationTimeout:any, transitionTimeout:any;
    
    const hideElement = () =>{
        // const heading = get('.heading').children;
        // const description = get('.desciption');
        // console.log()
        animateFullpage.default();
    }

    // clear animation
    const clearElement = () => {
        animateFullpage.clear();
    }

    const fullpage = $('#fullpage').fullpage({
        css3: true,
        lockAnchors: false,
        navigation: true,
		navigationPosition: 'right',

        // navigationTooltips: ['firstSlide', 'secondSlide'],
        scrollingSpeed: 1000,
        responsiveHeight: rpHeight,
        responsiveWidth: rpWidth,

        afterLoad: function(anchorLink:any, currentIndex:any) {
            // anchorLink : hashtag link
            // currentIndex: index current
            if (window.innerWidth >= rpWidth && window.innerHeight >= rpHeight){

                animateFullpage.fullIn(currentIndex); // page hiện tại từ 1 -> độ dài trang
            }
            // animateFullpage.fullIn(currentIndex); // page hiện tại từ 1 -> độ dài trang
        },
        onLeave: function(origin:any, nextIndex:any, direction:any){
            // // origin:
            // if (ready) return;
            
            // clearTimeout(animationTimeout);
            // clearTimeout(transitionTimeout);
            
            // // console.log(origin) // từ trang 1 -> 2 thì origin = 1, nextIndex = 2, 
            
            // animateFullpage.fullOut(origin);

            // // animation cho những section nằm ngoài fullpage
            // animationTimeout = setTimeout(()=>{
            //     ready = true;
            //     if (direction === "down"){
                    
            //     } else{
                    
            //     }
            //     transitionTimeout = setTimeout(()=>{
            //         ready = false;
            //     }, 300)
            // }, 300)

            if (window.innerWidth >= rpWidth && window.innerHeight >= rpHeight){
                // origin:
                if (ready) return false;
    
                clearTimeout(animationTimeout);
                clearTimeout(transitionTimeout);
    
                // console.log(origin) // từ trang 1 -> 2 thì origin = 1, nextIndex = 2, 
    
                animateFullpage.fullOut(origin);
    
                // animation cho những section nằm ngoài fullpage
                animationTimeout = setTimeout(() => {
                    // handleCustomNav(currentIndex);
                    ready = true;
                    if (direction === "down") {
    
                    } else {
    
                    }
                    transitionTimeout = setTimeout(() => {
                        ready = false;
                    }, 1300)
                }, 300)
            }
        }
    });

    // if (window.innerHeight < rpHeight || window.innerWidth < rpWidth){
    //     $.fn.fullpage.setResponsive(true);
    // }
    
    // let timeoutResize:any;
    // window.addEventListener('resize', function(){
    //     clearTimeout(timeoutResize);
    //     timeoutResize = setTimeout(function(){
    //         if (window.innerHeight < rpHeight || window.innerWidth < rpWidth){
    //             $.fn.fullpage.setResponsive(true);
    //         }else{
    //             $.fn.fullpage.setResponsive(false);
    //         }
    //     }, 300)
    // })

    // const {toArray, get} = new Utils();
    // hideElement();

    let fpCheck = false;
    
    if (window.innerWidth >= rpWidth && window.innerHeight >= rpHeight){
        hideElement();
        fpCheck = true;
    }
    else{
        clearElement();
        fpCheck = false;
    }

    let timeOutRsWin:any;
    window.addEventListener('resize', function(){
        clearTimeout(timeOutRsWin);
        timeOutRsWin = setTimeout(function(){
            if (window.innerWidth >= rpWidth && window.innerHeight >= rpHeight){
                if (fpCheck == false){
                    hideElement();
                    $.fn.fullpage.moveTo(1);
                    document.getElementById('fullpage').style.transform = "";
                    fpCheck = true;
                }
            }
            else{
                if (fpCheck == true){
                    clearElement();
                    fpCheck = false;
                }
            }
        }, 300)
    })
}

export const fullpage = function(){
    // setup fullpagejs
    setup();
}