declare var gsap : any;
declare var $ : any;
declare var anime : any;
// class Utils{
//     toArray(element:any){
//         return [].slice.call(element);
//     }
//     get(selector:any){
//         return document.querySelector(selector);
//     }
// }

class cAnimateFullpage{
    aniIn: Function;
    aniOut: Function;
    aniDefault: Function;
    clear: Function;

    constructor(animation:any){
        this.aniDefault = animation.aniOut;
        this.aniIn = animation.aniIn;
        this.aniOut = animation.aniOut;
        this.clear = animation.aniClear ? animation.aniClear: null;
    }
}

// khai báo và khởi tạo animation
let pageName = "";
if (document.getElementById('indexPage')){
    pageName = "index";
}
else if (document.getElementById('aboutPage')){
    pageName = "about";
}
else if (document.getElementById('servicePage')){
    pageName = "service";
}
else if (document.getElementById('contactPage')){
    pageName = "contact";
}
else if (document.getElementById('all-projectPage')){
    pageName = "all_project";
}

// index
import index1 from "../initAnimation/index/i-1";
const idx1 = new cAnimateFullpage(index1);
import index2 from "../initAnimation/index/i-2";
const idx2 = new cAnimateFullpage(index2);
import index3 from "../initAnimation/index/i-3";
const idx3 = new cAnimateFullpage(index3);
import index4 from "../initAnimation/index/i-4";
const idx4 = new cAnimateFullpage(index4);
import index5 from "../initAnimation/index/i-5";
const idx5 = new cAnimateFullpage(index5);
import index6 from "../initAnimation/index/i-6";
const idx6 = new cAnimateFullpage(index6);

// all project
import allproject1 from "../initAnimation/all_project/ap1";
const ap1 = new cAnimateFullpage(allproject1);

// about
import about1 from "../initAnimation/about/a-1";
const abt1 = new cAnimateFullpage(about1);
import about2 from "../initAnimation/about/a-2";
const abt2 = new cAnimateFullpage(about2);
import about3 from "../initAnimation/about/a-3";
const abt3 = new cAnimateFullpage(about3);

// servive
import service1 from "../initAnimation/service/s-1";
const srv1 = new cAnimateFullpage(service1);
import service2 from "../initAnimation/service/s-2";
const srv2 = new cAnimateFullpage(service2);
import service3 from "../initAnimation/service/s-3";
const srv3 = new cAnimateFullpage(service3);
import service4 from "../initAnimation/service/s-4";
const srv4 = new cAnimateFullpage(service4);
import service5 from "../initAnimation/service/s-5";
const srv5 = new cAnimateFullpage(service5);

// contact
import contact1 from "../initAnimation/contact/c-1";
const ctt1 = new cAnimateFullpage(contact1);
import contact2 from "../initAnimation/contact/c-2";
const ctt2 = new cAnimateFullpage(contact2);


function animationClear(){
    if (pageName === "index"){
        idx1.clear();
        idx2.clear();
        idx3.clear()
        idx4.clear();
        idx5.clear();
        idx6.clear();
    }
    else if (pageName === "all_project"){
        ap1.clear();
    }
    else if (pageName === "about"){
        abt1.clear();
        abt2.clear();
        abt3.clear();
    }
    else if (pageName === "contact"){
        ctt1.clear();
        ctt2.clear();
    }
    else if (pageName === "service"){
        srv1.clear();
        srv2.clear();
        srv3.clear();
        srv4.clear();
        srv5.clear();
    }
}

function animationDefault(){
    if (pageName === "index"){
        idx1.aniDefault();
        idx2.aniDefault();
        idx3.aniDefault()
        idx4.aniDefault();
        idx5.aniDefault();
        idx6.aniDefault();
    }
    else if (pageName === "all_project"){
        ap1.aniDefault();
    }
    else if (pageName === "about"){
        abt1.aniDefault();
        abt2.aniDefault();
        abt3.aniDefault();
    }
    else if (pageName === "contact"){
        ctt1.aniDefault();
        ctt2.aniDefault();
    }
    else if (pageName === "service"){
        srv1.aniDefault();
        srv2.aniDefault();
        srv3.aniDefault();
        srv4.aniDefault();
        srv5.aniDefault();
    }
    
}

let animationTimeout:any;

function animationIn(currentIndex:any){
    // start index >= 1
    clearTimeout(animationTimeout);
    animationTimeout = setTimeout(() => {
        if (pageName === "index"){
            if (currentIndex === 1){
                idx1.aniIn();
            }
            else if (currentIndex === 2){
                idx2.aniIn();
            }
            else if (currentIndex === 3){
                idx3.aniIn();
            }
            else if (currentIndex === 4){
                idx4.aniIn();
            }
            else if (currentIndex === 5){
                idx5.aniIn();
            }
            else if (currentIndex === 6){
                idx6.aniIn();
            }
        }
        else if (pageName === "about"){
            if(currentIndex === 1){
                abt1.aniIn();
            }
            else if(currentIndex === 2){
                abt2.aniIn();
            }
            else if(currentIndex === 3){
                abt3.aniIn();
            }
        }
        else if (pageName === "contact"){
            if (currentIndex === 1){
                ctt1.aniIn();
            }
            else if (currentIndex === 2){
                ctt2.aniIn();
            }
        }
        else if (pageName === "service"){
            if (currentIndex === 1){
                srv1.aniIn();
            }
            else if (currentIndex === 2){
                srv2.aniIn();
            }
            else if (currentIndex === 3){
                srv3.aniIn();
            }
            else if (currentIndex === 4){
                srv4.aniIn();
            }
            else if (currentIndex === 5){
                srv5.aniIn();
            }
        }
    })
}
function animationOut(currentIndex :number){
    clearTimeout(animationTimeout);
    animationTimeout = setTimeout(() =>{
        if (pageName === "index"){
            if (currentIndex === 1){
                idx1.aniOut();
            }
            else if (currentIndex === 2){
                idx2.aniOut();
            }
            else if (currentIndex === 3){
                idx3.aniOut();
            }
            else if (currentIndex === 4){
                idx4.aniOut();
            }
            else if (currentIndex === 5){
                idx5.aniOut();
            }
            else if (currentIndex === 6){
                idx6.aniOut();
            }
        }
        else if (pageName === "about"){
            if(currentIndex === 1){
                abt1.aniOut();
            }
            else if(currentIndex === 2){
                abt2.aniOut();
            }
            else if(currentIndex === 3){
                abt3.aniOut();
            }
        }
        else if (pageName === "contact"){
            if (currentIndex === 1){
                ctt1.aniOut();
            }
            else if (currentIndex === 2){
                ctt2.aniOut();
            }
        }
        else if (pageName === "service"){
            if (currentIndex === 1){
                srv1.aniOut();
            }
            else if (currentIndex === 4){
                srv4.aniOut();
            }
            else if (currentIndex === 2){
                srv2.aniOut();
            }
            else if (currentIndex === 3){
                srv3.aniOut();
            }
            else if (currentIndex === 5){
                srv5.aniOut();
            }
        }
    }, 1000)
}

export default{
    default: animationDefault,
    fullIn: animationIn,
    fullOut: animationOut,
    clear: animationClear,
}