function SVGCreate(){
    var nav = document.querySelectorAll('.nav');
    if(nav){
        nav.forEach(function(element){
            var sw_prev = document.createElement("div")
            var sw_next = document.createElement("div")
            // prev
            sw_prev.setAttribute("class","swiper-button-prev")
            // create svg prev
            var svg_prev = document.createElement('div')
            svg_prev.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="62.048" height="25.789" viewBox="0 0 62.048 25.789"><g id="Group_3912" data-name="Group 3912" transform="translate(-1632.152 -906.391)"><path id="Path_10910" data-name="Path 10910" d="M-569.05,12571.744l-12.541,12.54,12.541,12.542" transform="translate(2214.45 -11665)" fill="none" stroke="#4e8848" stroke-width="1"/><path id="Path_10912" data-name="Path 10912" d="M977.859,12880.285H1039.2" transform="translate(655 -11961)" fill="none" stroke="#4e8848" stroke-width="1"/></g></svg>';
            sw_prev.appendChild(svg_prev); 
    
            // next
            sw_next.setAttribute("class","swiper-button-next")
            // create svg next
            var svg_next = document.createElement('div')
            svg_next.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="62.048" height="25.789" viewBox="0 0 62.048 25.789"><g id="Group_3913" data-name="Group 3913" transform="translate(0 0.354)"><path id="Path_10910" data-name="Path 10910" d="M-581.591,12571.744l12.541,12.54-12.541,12.542" transform="translate(630.391 -12571.744)" fill="none" stroke="#4e8848" stroke-width="1"/><path id="Path_10912" data-name="Path 10912" d="M1039.2,12880.285H977.859" transform="translate(-977.859 -12867.744)" fill="none" stroke="#4e8848" stroke-width="1"/></g></svg>';
            sw_next.appendChild(svg_next); 
            
            element.appendChild(sw_prev); 
            element.appendChild(sw_next);
        })
    }
}

export const swiper_nav = function(){
    SVGCreate();
}