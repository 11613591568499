function lerp(start:any, end:any, amount:any){
    return (1 - amount) * start + amount * end;
}

const cursor = document.createElement('div');
cursor.className = 'cursor';

const cursorF = document.createElement('div');
cursorF.className = 'cursor-f';

let cursorX = 0;
let cursorY = 0;
let pageX = 0;
let pageY = 0;
let size = 8;
let sizeF = 36;
let followSpeed = .9;

document.body.appendChild(cursor);
document.body.appendChild(cursorF);

if ('ontouchstart' in window){
    cursor.style.display = 'none';
    cursorF.style.display = 'none';
}

cursor.style.setProperty('--size', size+'px');
cursor.style.setProperty('--size', sizeF+'px');

window.addEventListener('mousemove', function(e){
    pageX = e.clientX;
    pageY = e.clientY;
    cursor.style.left = e.clientX - size / 2 + 'px';
    cursor.style.top = e.clientY - size / 2 + 'px';

})

function loop(){
    cursorX = lerp(cursorX, pageX, followSpeed);
    cursorY = lerp(cursorX, pageY, followSpeed);
    cursorF.style.top = cursorY - sizeF/2 + 'px';
    cursorF.style.left = cursorX - sizeF/2 + 'px';
    requestAnimationFrame(loop);
}

export const startCursor = function (){
    loop();
}