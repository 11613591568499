declare var gsap : any;
declare var Power2 : any;
declare var Swiper: any;

let animationGsap = gsap.timeline();

function clear (){
    animationGsap.clear();
}

function aniIn(){
    clear()
    animationGsap.from('#brn-index-8 .brn-index-8__wrapper--project.active  .swiper-slide', {
        x: 0,
        opacity: 1,
        duration: .5,
        stagger: 0.2 ,
    })
}

function aniOut(){
    clear()
    animationGsap.from('#brn-index-8 .brn-index-8__wrapper--project.active .swiper-slide', {
        x: 100,
        opacity: 0,
        duration: 0,
        stagger: 0 ,
    })
}

function aniClear(){
    clear()
    animationGsap.from('#brn-index-8 .brn-index-8__wrapper--project.active .swiper-slide', {
        x: 0,
        opacity: 1,
        duration: 0,
    })
}

export default{
    aniIn,
    aniOut,
    aniClear
}