declare var Swiper: any;
declare var gsap: any;
let animationGsap = gsap.timeline();

const rpWidth = 1280;
const rpHeight = 540;

// function clear() {
//     animationGsap.clear();
// }
function autoBodyBrand() {
    let brand = document.querySelector('#all-projectPage');
    if (brand) {
        let headHtml = document.querySelector('head');
        headHtml.innerHTML += `<style>body, html{overflow-x:hidden;overflow-y:auto;height:auto}</style>`;

        let header = <HTMLElement>document.querySelector('.header');
        let bannerBrand = <HTMLElement>document.querySelector('.brn-index-8');
        let footer = <HTMLElement>document.getElementById('footer');

        if (bannerBrand && header) {
            //   bannerBrand.style.paddingTop = header.clientHeight + "px";

            if (window.innerWidth >= rpWidth && window.innerHeight >= rpHeight) {
                bannerBrand.style.paddingTop = header.offsetHeight + "px";
            }
            let timeOutRsWin: any;
            window.addEventListener('resize', function () {
                clearTimeout(timeOutRsWin);
                timeOutRsWin = this.setTimeout(function () {
                    if (window.innerWidth >= rpWidth && window.innerHeight >= rpHeight) {
                        bannerBrand.style.paddingTop = header.offsetHeight + "px";
                    }
                    else {
                        bannerBrand.style.paddingTop = "";
                    }
                }, 300)
            })

            header.classList.add('scrollDown');

            let timeOutScroll: any;
            document.addEventListener('scroll', function () {
                clearTimeout(timeOutScroll);
                timeOutScroll = setTimeout(function () {
                    if (window.scrollY > 100) {
                        header.style.boxShadow = "0 3px 6px rgba(0,0,0,.16)";
                    }
                    else {
                        header.style.boxShadow = "none";
                    }
                }, 200)
            })
        }

        if (footer) {
            footer.classList.remove('full');
            footer.classList.add('relate');
        }
    }
}

// all project
// import allproject1 from "../initAnimation/all_project/ap1";

function swiperAllProject() {
    const AllProject = document.getElementById("brn-index-8")
    if (AllProject) {
        const projectActive = AllProject.querySelector(".brn-index-8__wrapper--project.active")
        if (projectActive) {
            const swiper = projectActive.querySelector(".MyAllProjects")
            if (swiper) {
                var sw = new Swiper(swiper, {
                    spaceBetween: 80,
                    navigation: {
                        nextEl: ".brn-index-8__wrapper--project.active .swiper-button-next",
                        prevEl: ".brn-index-8__wrapper--project.active .swiper-button-prev",
                    },
                    speed: 1400,
                    autoplay: {
                        delay: 3500,
                        disableOnInteraction: false,
                    },
                    breakpoints: {
                        0: {
                            spaceBetween: 20,
                            slidesPerView: 1,
                            grid: {
                                fill: 'row',
                                rows: 2,
                                columns: 2
                            },
                        },
                        577: {
                            spaceBetween: 20,
                            slidesPerView: 2,
                            grid: {
                                fill: 'row',
                                rows: 2,
                                columns: 2
                            },
                        },
                        769: {
                            spaceBetween: 20,
                            slidesPerView: 2,
                            grid: {
                                fill: 'row',
                                rows: 2,
                                columns: 2
                            },
                        },
                        993: {
                            spaceBetween: 40,
                            slidesPerView: 2,
                            grid: {
                                fill: 'row',
                                rows: 2,
                                columns: 2
                            },
                        },
                        1201: {
                            spaceBetween: 40,
                            slidesPerView: 3,
                            grid: {
                                fill: 'row',
                                rows: 2,
                                columns: 3
                            },
                        },
                        1441: {
                            spaceBetween: 80,
                            slidesPerView: 3,
                            grid: {
                                fill: 'row',
                                rows: 2,
                                columns: 3
                            },
                        }
                    },

                    initialSlide: 0
                })

                return sw;
            }
        }
    }

}

function changeProject() {
    const AllProject = document.getElementById("brn-index-8")

    var sw = swiperAllProject();

    if (AllProject) {
        const navProject = AllProject.querySelectorAll(".brn-index-8__wrapper--intro_title")
        navProject.forEach(element => element.addEventListener('click', () => {
            const navActive = AllProject.querySelector(".brn-index-8__wrapper--intro_title.active")
            const projectActive = AllProject.querySelector(".brn-index-8__wrapper--project.active")
            
            sw.destroy()

            if (navActive) {
                // navActive.classList.remove("active")
                navActive.classList.remove("active")

            }
            if (projectActive) {
                // allproject1.aniIn()
                projectActive.classList.remove("active")

            }
            element.classList.add("active")

            const nav_toggle = element.getAttribute("data-active")
            const myProject = AllProject.querySelectorAll(".brn-index-8__wrapper--project")
            myProject.forEach(function (elementProjects) {
                const project_toggle = elementProjects.getAttribute("data-active")
                if (nav_toggle === project_toggle) {
                    elementProjects.classList.add("active")

                    sw = swiperAllProject();
                    // allproject1.aniOut()
                }

            })
        }))
    }
}





export const all_project = function () {
    autoBodyBrand();
    // all project
    changeProject();
    // swiperAllProject();
    // disableScroll();

}