declare var gsap : any;

let animationGsap = gsap.timeline();

function clear(){
    animationGsap.clear();
}

const aniIn = function(){
    clear();

    animationGsap
    .to('#service-4 .service-4__title .title__name',{
        y: 0,
        opacity: 1,
        duration: .16,
    })
    .to('#service-4 .service-4__title .title__desc',{
        y: 0,
        opacity: 1,
        duration: .16,
    })
    .to('#service-4 .service-4__content',{
        y: 0,
        opacity: 1,
        duration: .16,
    })
    .to('#service-4 .service-4__content .brand-service__infor',{
        x: 0,
        opacity: 1,
        duration: .16,
    })
    .to('#service-4 .service-4__content .brand-service__img',{
        opacity: 1,
        duration: .16,
    }, "<")
    .to('#service-4 .serSvg04 .setting01', {
        opacity: 1,
        duration: .16,
    })
    .to('#service-4 .serSvg04 .setting02', {
        opacity: 1,
        duration: .16,
    }, "<")
    .to('#service-4 .serSvg04 .setting01', {
        rotate: -359,
        duration: 6,
        repeat: -1,
        transformOrigin: 'center',
        ease: "linear",
    })
    .to('#service-4 .serSvg04 .setting02', {
        rotate: 359,
        duration: 6,
        repeat: -1,
        transformOrigin: 'center',
        ease: "linear",
    }, "<0.3")
    .to('#service-4 .serSvg04 .speak', {
        scale: 1,
        opacity: 1,
        duration: 0.3,
        transformOrigin: 'center'
    }, "1.5")
    .to('#service-4 .serSvg04 .codeColor01', {
        scale: 1,
        x: 0,
        opacity: 1,
        duration: 0.2,
        transformOrigin: 'bottom'
    })
    .to('#service-4 .serSvg04 .codeColor02', {
        scale: 1,
        x: 0,
        opacity: 1,
        duration: 0.2,
        transformOrigin: 'bottom'
    }, "<0.1")
    .to('#service-4 .serSvg04 .codeColor03', {
        scale: 1,
        x: 0,
        opacity: 1,
        duration: 0.2,
        transformOrigin: 'bottom'
    }, "<0.1")

    .to('#service-4 .serSvg04 .member01', {
        x: 0,
        opacity: 1,
        duration: 0.3,
        transformOrigin: 'center'
    }, "1.5")
    .to('#service-4 .serSvg04 .member03', {
        scale: 1,
        opacity: 1,
        duration: 0.3,
        transformOrigin: 'center'
    })
    .to('#service-4 .serSvg04 .member02', {
        scale: 1,
        opacity: 1,
        duration: 0.3,
        transformOrigin: 'center'
    })
    .to('#service-4 .serSvg04 .idea', {
        scale: 1,
        opacity: 1,
        duration: 0.3,
        transformOrigin: 'bottom'
    }, "<")
    .to('#service-4 .serSvg04 .member04', {
        x: 0,
        opacity: 1,
        duration: 0.3,
        transformOrigin: 'center'
    })
    .to('#service-4 .serSvg04 .inboxSpeak', {
        scale: 1,
        opacity: 1,
        duration: 0.3,
        transformOrigin: 'left bottom'
    })
    .to('#service-4 .serSvg04 .searchGr', {
        scale: 1,
        opacity: 1,
        duration: 0.16,
        transformOrigin: 'left center'
    })
    .to('#service-4 .serSvg04 .coin', {
        scale: 0.9,
        opacity: 1,
        transformOrigin: 'center',
        stagger:{
            amount: 0.2
        },
    })
    .to('#service-4 .serSvg04 .coin01', {
        translateY: -120,
        repeat: -1,
        yoyo: true,
        transformOrigin: 'center',
        scale: 1,
        duration: 1
    })
    .to('#service-4 .serSvg04 .coin02', {
        translateY: -20,
        repeat: -1,
        yoyo: true,
        transformOrigin: 'center',
        scale: 1,
        duration: 1.6
    }, "<0.3")
    .to('#service-4 .serSvg04 .coin03', {
        translateY: -10,
        repeat: -1,
        yoyo: true,
        transformOrigin: 'center',
        scale: 1,
        duration: 1.7
    }, "<0.5")
    .to('#service-4 .serSvg04 .coin04', {
        translateY: 0,
        repeat: -1,
        yoyo: true,
        transformOrigin: 'center',
        scale: 1,
        duration: 1.9
    }, "<0.2")
}

const aniOut = function(){
    clear();
    animationGsap
    .to('#service-4 .service-4__title .title__name',{
        y: -50,
        opacity: 0,
        duration: 0, 
        }, "0")
    .to('#service-4 .service-4__title .title__desc',{
        y: 50,
        opacity: 0,
        duration: 0,
    }, "0")
    .to('#service-4 .service-4__content',{
        y: 150,
        opacity: 0,
        duration: 0,
    }, "0")
    .to('#service-4 .service-4__content .brand-service__infor',{
        x: -150,
        opacity: 0,
        duration: 0,
    }, "0")
    .to('#service-4 .service-4__content .brand-service__img',{
        opacity: 0,
        duration: 0,
    }, "0")
    .to('#service-4 .serSvg04 .setting01', {
        opacity: 0,
        duration: 0,
        rotate: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-4 .serSvg04 .setting02', {
        rotate: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-4 .serSvg04 .speak', {
        scale: 2.2,
        opacity: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-4 .serSvg04 .codeColor01', {
        scale: 2.2,
        x: -120,
        opacity: 0,
        duration: 0,
        transformOrigin: 'bottom'
    },"0")
    .to('#service-4 .serSvg04 .codeColor02', {
        scale: 2.2,
        x: -120,
        opacity: 0,
        duration: 0,
        transformOrigin: 'bottom'
    },"0")
    .to('#service-4 .serSvg04 .codeColor03', {
        scale: 2.2,
        x: -120,
        opacity: 0,
        duration: 0,
        transformOrigin: 'bottom'
    },"0")
    .to('#service-4 .serSvg04 .member01', {
        x: -120,
        opacity: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-4 .serSvg04 .member03', {
        scale: 2.2,
        opacity: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-4 .serSvg04 .member02', {
        scale: 0,
        opacity: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-4 .serSvg04 .idea', {
        scale: 0,
        opacity: 0,
        duration: 0,
        transformOrigin: 'bottom'
    },"0")
    .to('#service-4 .serSvg04 .member04', {
        x: 120,
        opacity: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-4 .serSvg04 .inboxSpeak', {
        scale: 0,
        opacity: 0,
        duration: 0,
        transformOrigin: 'left bottom'
    },"0")
    .to('#service-4 .serSvg04 .searchGr', {
        scale: 0,
        opacity: 0,
        duration: 0,
        transformOrigin: 'left center'
    },"0")
    
    .to('#service-4 .serSvg04 .coin01', {
        rotationY: 0,
        opacity: 0,
        duration: 0,
        transformOrigin: 'center',
        rotate: 0,
        scale: 0,
    },"0")
    .to('#service-4 .serSvg04 .coin02', {
        opacity: 0,
        duration: 0,
        transformOrigin: 'center',
        scale: 0,
        translateY: 100
    },"0")
    .to('#service-4 .serSvg04 .coin03', {
        opacity: 0,
        duration: 0,
        transformOrigin: 'center',
        scale: 0,
        translateY: 100
    },"0")
    .to('#service-4 .serSvg04 .coin04', {
        opacity: 0,
        duration: 0,
        transformOrigin: 'center',
        scale: 0,
        translateY: 90
    },"0")
}

const aniClear = function(){
    clear();
    animationGsap
    .to('#service-4 .service-4__title .title__name',{
        y: 0,
        opacity: 1,
        duration: 0, 
        }, "0")
    .to('#service-4 .service-4__title .title__desc',{
        y: 0,
        opacity: 1,
        duration: 0,
    }, "0")
    .to('#service-4 .service-4__content',{
        y: 0,
        opacity: 1,
        duration: 0,
    }, "0")
    .to('#service-4 .service-4__content .brand-service__infor',{
        x: 0,
        opacity: 1,
        duration: 0,
    }, "0")
    .to('#service-4 .service-4__content .brand-service__img',{
        opacity: 1,
        duration: 0,
    }, "0")
    .to('#service-4 .serSvg04 .setting01', {
        opacity: 1,
        duration: 0,
        rotate: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-4 .serSvg04 .setting02', {
        rotate: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-4 .serSvg04 .speak', {
        scale: 1,
        opacity: 1,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-4 .serSvg04 .codeColor01', {
        scale: 1,
        x: 0,
        opacity: 1,
        duration: 0,
        transformOrigin: 'bottom'
    },"0")
    .to('#service-4 .serSvg04 .codeColor02', {
        scale: 1,
        x: 0,
        opacity: 1,
        duration: 0,
        transformOrigin: 'bottom'
    },"0")
    .to('#service-4 .serSvg04 .codeColor03', {
        scale: 1,
        x: 0,
        opacity: 1,
        duration: 0,
        transformOrigin: 'bottom'
    },"0")
    .to('#service-4 .serSvg04 .member01', {
        x: 0,
        opacity: 1,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-4 .serSvg04 .member03', {
        scale: 1,
        opacity: 1,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-4 .serSvg04 .member02', {
        scale: 1,
        opacity: 1,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-4 .serSvg04 .idea', {
        scale: 1,
        opacity: 1,
        duration: 0,
        transformOrigin: 'bottom'
    },"0")
    .to('#service-4 .serSvg04 .member04', {
        x: 0,
        opacity: 1,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-4 .serSvg04 .inboxSpeak', {
        scale: 1,
        opacity: 1,
        duration: 0,
        transformOrigin: 'left bottom'
    },"0")
    .to('#service-4 .serSvg04 .searchGr', {
        scale: 1,
        opacity: 1,
        duration: 0,
        transformOrigin: 'left center'
    },"0")
    
    .to('#service-4 .serSvg04 .coin01', {
        // rotationY: 0,
        opacity: 1,
        duration: 0,
        transformOrigin: 'center',
        rotate: 0,
        scale: 1,
    },"0")
    .to('#service-4 .serSvg04 .coin02', {
        opacity: 1,
        duration: 0,
        transformOrigin: 'center',
        scale: 1,
        translateY: 0
    },"0")
    .to('#service-4 .serSvg04 .coin03', {
        opacity: 1,
        duration: 0,
        transformOrigin: 'center',
        scale: 1,
        translateY: 0
    },"0")
    .to('#service-4 .serSvg04 .coin04', {
        opacity: 1,
        duration: 0,
        transformOrigin: 'center',
        scale: 1,
        translateY: 0
    },"0")
}

export default{
    aniIn,
    aniOut,
    aniClear
}