function checkedHandle(){
    let formContact = document.querySelector('#formContact');
    if (formContact){
        formContact.addEventListener('submit', function(e){
            e.preventDefault();
            // alert(1)
        })
    }
}

export const contact = function(){
    checkedHandle();
}