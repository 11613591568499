declare var gsap: any;
// declare var Power2 : any;
declare var Swiper: any;

let animationGsap = gsap.timeline();
var sw = new Swiper(".brn-index-client .MyClient", {
    spaceBetween: 10,
    slidesPerView: 1,
    pagination: {
      el: ".brn-index-client__wrapper--client__list .swiper-pagination",
      dynamicBullets: true,
    },
    grabCursor: true,
    speed: 1400,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
})
sw.initialSlide = 0;
sw.autoplay.stop();

var sw1 = new Swiper(".brn-index-client .MyClientLogo", {
	spaceBetween: 30,
	slidesPerView: 0.66667,

	// direction: "vertical",
	grabCursor: true,
	speed: 1400,
	autoplay: {
		delay: 2500,
		disableOnInteraction: false,
	},

	breakpoints: {
		0: {
			slidesPerView: 0.66667,
			direction: "horizontal",
			autoplay: {
				delay: 2500,
			},
			loop: true,
		},
		992: {
			direction: "vertical",
			slidesPerView: 3,
			autoplay: {
				delay: 2500,
			},
			loop: false,
		},
	},
});
sw1.initialSlide = 0;
sw1.autoplay.stop();

function clear() {
	animationGsap.clear();
}

function aniIn() {
	clear();

	animationGsap
		.to("#brn-index-client .brn-index-client__wrapper--client__title", {
			x: 0,
			opacity: 1,
			duration: 0.5,
			delay: 0.1,
		})
		.to("#brn-index-client .brn-index-client__wrapper--client__list", {
			opacity: 1,
			duration: 0.5,
		})
		.to(
			"#brn-index-client .MyClient .swiper-pagination",
			{
				y: 0,
				opacity: 1,
				duration: 0.5,
			},
			"<-.2",
		)
		.to(
			"#brn-index-client .MyClientLogo .swiper-slide",
			{
				y: 0,
				opacity: 1,
				duration: 0.5,
				stagger: 0.2,
			},
			"<.3",
		)
		.to(
			"#brn-index-client .client_line",
			{
				y: 0,
				opacity: 1,
				duration: 0.6,
			},
			"<+=.2",
		)
		.to(
			"#brn-index-client .client_plants",
			{
				y: 0,
				opacity: 1,
				duration: 0.7,
			},
			"<",
		)
		.to(
			"#brn-index-client .client_handshake",
			{
				opacity: 1,
				duration: 0.7,
			},
			"<+=.2",
		)
		// .to('#brn-index-client .client_handshake_circle', {
		//     scale:1,
		//     rotate: 359,
		//     duration: 6,
		//     repeat: -1,
		//     transformOrigin: 'center',
		//     ease: "linear",
		// },"<+=.2")
		.to(
			"#brn-index-client .client_boyL",
			{
				x: 0,
				opacity: 1,
				duration: 0.6,
				stagger: 0.2,
			},
			"<+=.2",
		)
		.to(
			"#brn-index-client .client_chair",
			{
				x: 0,
				opacity: 1,
				duration: 0.5,
				stagger: 0.2,
			},
			"<+=.2",
		)
		.to(
			"#brn-index-client .client_boyR",
			{
				x: 0,
				opacity: 1,
				duration: 0.6,
				stagger: 0.2,
			},
			"<+=.2",
		);


	sw.autoplay.start();
	sw1.autoplay.start();
}

function aniOut() {
	clear();
	animationGsap
		.to("#brn-index-client .brn-index-client__wrapper--client__title", {
			x: 100,
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-client .brn-index-client__wrapper--client__list", {
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-client .MyClient .swiper-pagination", {
			y: 100,
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-client .MyClientLogo .swiper-slide", {
			y: 100,
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-client .client_line", {
			y: 100,
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-client .client_plants", {
			y: 100,
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-client .client_handshake", {
			opacity: 0,
			duration: 0,
		})
		// .to('#brn-index-client .client_handshake_circle',{
		//     scale:1.3,
		//     rotate: 0,
		//     duration: 0,
		//     transformOrigin: 'center'
		// })
		.to("#brn-index-client .client_boyL", {
			x: -100,
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-client .client_chair", {
			x: 100,
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-client .client_boyR", {
			x: 100,
			opacity: 0,
			duration: 0,
		});

	sw.autoplay.stop();
	sw1.autoplay.stop();

	sw.slideTo(0);
	sw1.slideTo(0);

}

function aniClear() {
	clear();
	animationGsap
		.to("#brn-index-client .brn-index-client__wrapper--client__title", {
			x: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-client .brn-index-client__wrapper--client__list", {
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-client .MyClient .swiper-pagination", {
			y: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-client .MyClientLogo .swiper-slide", {
			y: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-client .client_line", {
			y: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-client .client_plants", {
			y: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-client .client_handshake", {
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-client .client_boyL", {
			x: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-client .client_chair", {
			x: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-client .client_boyR", {
			x: 0,
			opacity: 1,
			duration: 0,
		});

}

export default {
	aniIn,
	aniOut,
	aniClear,
};
