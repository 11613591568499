declare var Swiper : any;
// declare var ScrollTrigger: any;
declare var gsap: any;

const rpWidth = 1280;
const rpHeight = 540;

function autoBodyBrand (){
    let brand = document.querySelector('#newsPage');
    if (brand){
        let headHtml = document.querySelector('head');
        headHtml.innerHTML += `<style>body, html{overflow-x:hidden;overflow-y:auto;height:auto}</style>`;
    
        let header = <HTMLElement>document.querySelector('.header');
        let spaceHeader = <HTMLElement> document.querySelector('.news1');
        let footer = <HTMLElement> document.getElementById('footer');

        if (spaceHeader && header){
            if (window.innerWidth >= rpWidth && window.innerHeight >= rpHeight){
                spaceHeader.style.paddingTop = header.clientHeight + "px";
            }
            let timeOutRsWin: any;
            window.addEventListener('resize', function(){
                clearTimeout(timeOutRsWin);
                timeOutRsWin = this.setTimeout(function(){
                    if (window.innerWidth >= rpWidth && window.innerHeight >= rpHeight){
                        spaceHeader.style.paddingTop = header.clientHeight + "px";
                    }
                    else{
                        spaceHeader.style.paddingTop = "";
                    }
                }, 300)
            })

            header.classList.add('scrollDown');
        
            let timeOutScroll: any;

            document.addEventListener('scroll', function(){
                clearTimeout(timeOutScroll);
                timeOutScroll = setTimeout(function(){
                    if (window.scrollY > 100){
                        header.style.boxShadow = "0 3px 6px rgba(0,0,0,.16)";
                    }
                    else{
                        header.style.boxShadow = "none";
                    }
                }, 200)
            })
        }

        if (footer){
            footer.classList.remove('full');
            footer.classList.add('relate');
        }
    }
}

function swiperNews(){
    var new1Swiper = document.querySelector('.news1');
    if (new1Swiper){
        new Swiper('.news1 .new1-cards-swiper', {
            slidesPerView: 3,
            slidesPerColumn: 1,
            slidesPerGroup: 3,
            spaceBetween: 40,

            speed: 1400,

            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
            },

            breakpoints:{
                0:{
                    spaceBetween: 10,
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
                577: {
                    spaceBetween: 10,
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                769:{
                    spaceBetween: 10,
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                993:{
                    spaceBetween: 20,
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                1201:{
                    spaceBetween: 40,
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                }
            },

            navigation: {
                nextEl: ".news1 .swiper-button-next",
                prevEl: ".news1 .swiper-button-prev",
            },
        })
    }
}

function animation(){
    if (document.querySelector('.card')){
        gsap.from('.card', {
            opacity: 0,
            x: 120,
            stagger:{
                each: 0.5
            },
            scrollStrigger: ".card"
        })
    }
}


export const news = function(){
    autoBodyBrand();
    swiperNews();
    animation();
}