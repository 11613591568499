// https://fancyapps.com/docs/ui/fancybox
// https://www.npmjs.com/package/axios
// https://greensock.com/
// https://swiperjs.com/get-started
declare var Swiper: any;
declare var $: any;
declare var AOS: any;
declare var gsap: any;
declare var anime: any;
declare var ScrollTrigger: any;
gsap.registerPlugin(ScrollTrigger);

// declare var fullpage : any;

// new fullpage('#fullpage', {
//   autoScrolling: true
// })

// AOS.init({
//   once: true
// });

// import axios from "axios";
import { swiper_nav } from "./layout/swiper-nav";
swiper_nav();

//////////////////////////////////////////////////////////
// Common function
import { fullpage } from "./components/fullpage";
import { startCursor } from "./components/cursor";
// import * as fetchData from "./components/fetchData";
// import {gsaplib} from "./components/gsap";
// import aniGsap from './components/gsap';

import {boxProcedure} from "./components/_box-procedure";

// Layout
import { header } from "./layout/header";

// Page
import { about } from "./page/about";
import { brand } from "./page/brand";
import { news } from "./page/news";
import { contact } from "./page/contact";

import { newsDetail } from "./page/newsDetail";
import { serviceDetail } from "./page/serviceDetail";
import { all_project } from "./page/all-project";

// import {index} from "./pages/index";
import { index } from "./page/index";

// import { interpolate } from "gsap/all";

///////////////////////////////////////////////////////////////
// function Common
function common() {
	startCursor();
	fullpage();
}

function layout() {
	header();
}

// function Page
function page() {
	// ----- add page in here ----- //
	index();
	about();
	news();
	contact();
	brand();
	all_project();
	newsDetail();
	serviceDetail();

	boxProcedure();
}

function allJs() {
	page();
	layout();
}

////////////////////////////////////////////////////////////////

let loading = document.querySelector("#loading-container");
import loadingAni from "./initAnimation/loading";

loadingAni.aniIn();
allJs();

// import animateFullpage from "./components/animateFullpage";
// animateFullpage.default();

window.addEventListener("DOMContentLoaded", function () {
	setTimeout(function () {
		loading.classList.add("hiddenLoading");
		setTimeout(function () {
			loading.parentElement.removeChild(loading);
		}, 900);
		common();
	}, loadingAni.delay);
});

// let deferredPrompt: any;
// const addBtn = <HTMLElement>document.querySelector(".addHomeScreen");
// addBtn.style.display = "none";

// "serviceWorker" in navigator &&
// 	(window.addEventListener("load", function () {
//     console.log('ok');
// 		navigator.serviceWorker
// 			.register("https://braney.vn/api/" + "sw.js")
// 			.then(
// 				function (e) {
// 					console.log(
// 						"ServiceWorker registration successful with scope: ",
// 						e.scope,
// 					);
// 				},
// 				function (e) {
// 					console.log("ServiceWorker registration failed: ", e);
// 				},
// 			);
// 	}),
// 	window.addEventListener("beforeinstallprompt", (e) => {
// 		alert("readly!");
// 		// Prevent Chrome 67 and earlier from automatically showing the prompt
// 		e.preventDefault();
// 		// Stash the event so it can be triggered later.
// 		deferredPrompt = e;
// 		// Update UI to notify the user they can add to home screen
// 		addBtn.style.display = "block";
// 		addBtn.addEventListener("click", (e) => {
// 			// hide our user interface that shows our A2HS button
// 			addBtn.style.display = "none";
// 			// Show the prompt
// 			deferredPrompt.prompt();
// 			// Wait for the user to respond to the prompt
// 			deferredPrompt.userChoice.then((choiceResult: any) => {
// 				if (choiceResult.outcome === "accepted") {
// 					console.log("User accepted the A2HS prompt");
// 				} else {
// 					console.log("User dismissed the A2HS prompt");
// 				}
// 				deferredPrompt = null;
// 			});
// 		});
// 	}));
