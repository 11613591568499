declare var gsap : any;

var animationAbout2 = gsap.timeline();
function clear (){
    animationAbout2.clear();
}
function aniIn(){
    clear();
    animationAbout2
    .to('#about-2 .about-2__title .title__name',{
        y: 0 ,  opacity: 1, duration: 0.2, 
    })
    .to('#about-2 .about-2__title .title__desc',{
        y: 0 ,  opacity: 1, duration: 0.2, 
    })
    .to('#about-2 .about-2__content',{
        y: 0 ,  opacity: 1, duration: 0.2, 
    })
    .to('#about-2 .about-2__content .about-2__box',{
        scale: 1,  opacity: 1,
        stagger: {
            amount: 0.4,
            from: "center"
        } 
    })
}

function aniOut(){
    clear();
    animationAbout2
    .to('#about-2 .about-2__title .title__name',{
        y: -50,
        opacity: 0,
        duration: 0, 
    })
    .to('#about-2 .about-2__title .title__desc',{
        y: -50,
        opacity: 0,
        duration: 0, 
    })
    .to('#about-2 .about-2__content',{
        y: 500,
        opacity: 0,
        duration: 0, 
    })
    .to('#about-2 .about-2__content .about-2__box',{
        scale: 3,
        opacity: 0,
        duration: 0, 
    })
}

function aniClear(){
    clear();
    animationAbout2
    .to('#about-2 .about-2__title .title__name',{
        y: 0,
        opacity: 1,
        duration: 0, 
    })
    .to('#about-2 .about-2__title .title__desc',{
        y: 0,
        opacity: 1,
        duration: 0, 
    })
    .to('#about-2 .about-2__content',{
        y: 0,
        opacity: 1,
        duration: 0, 
    })
    .to('#about-2 .about-2__content .about-2__box',{
        scale: 1,
        opacity: 1,
        duration: 0, 
    })
}

export default{
    aniIn,
    aniOut,
    aniClear
}