declare var gsap : any;

let animationGsap = gsap.timeline();

function clear(){
    animationGsap.clear();
}


const aniIn = function(){
    clear();
    animationGsap
    .to('#service-5 .service-5__title .title__name',{
        y: 0,
        opacity: 1,
        duration: .16,
    })
    .to('#service-5 .service-5__title .title__desc',{
        y: 0,
        opacity: 1,
        duration: .16,
    })
    .to('#service-5 .service-5__content',{
        y: 0,
        opacity: 1,
        duration: .16,
    })
    .to('#service-5 .service-5__content .brand-service__infor',{
        x: 0,
        opacity: 1,
        duration: .16,
    })
    .to('#service-5 .service-5__content .brand-service__img',{
        opacity: 1,
        duration: .16,
    }, "<")
    .to('#service-5 .serSvg05 .setting01', {
        opacity: 1,
        duration: .16,
    })
    .to('#service-5 .serSvg05 .setting02', {
        opacity: 1,
        duration: .16,
    }, "<")
    .to('#service-5 .serSvg05 .setting01', {
        rotate: -359,
        duration: 6,
        repeat: -1,
        transformOrigin: 'center',
        ease: "linear",
    })
    .to('#service-5 .serSvg05 .setting02', {
        rotate: 359,
        duration: 6,
        repeat: -1,
        transformOrigin: 'center',
        ease: "linear",
    }, "<0.3")
    .to('#service-5 .serSvg05 .television', {
        scale: 1,
        duration: .16,
        opacity: 1,
        transformOrigin: 'center',
        ease: "linear",
    }, "1.25")
    .to('#service-5 .serSvg05 .tagOpenClose', {
        scale: 1,
        duration: 1,
        opacity: 1,
        transformOrigin: 'center',
        ease: "linear",
    }, "1.5")
    .to('#service-5 .serSvg05 .tagHtml', {
        scale: 1,
        duration: 1,
        opacity: 1,
        transformOrigin: 'center',
        ease: "linear",
    }, "1.5")
    .to('#service-5 .serSvg05 .desginer', {
        y: 0,
        opacity: 1,
        duration: 0.3,
        transformOrigin: 'center'
    }, "-=1")
    .to('#service-5 .serSvg05 .desginImg', {
        scale: 1,
        opacity: 1,
        duration: 0.6,
        transformOrigin: 'center'
    }, "<0.2")

    .to('#service-5 .serSvg05 .book01', {
        scale: 1,
        y: 0,
        opacity: 1,
        duration: .4,
        transformOrigin: 'center'
    })
    .to('#service-5 .serSvg05 .book02', {
        scale: 1,
        y: 0,
        opacity: 1,
        duration: .4,
        transformOrigin: 'center'
    })
    .to('#service-5 .serSvg05 .instructor', {
        scale: 1,
        opacity: 1,
        duration: .16,
        transformOrigin: 'center'
    })
}

const aniOut = function(){
    clear();
    animationGsap
    .to('#service-5 .service-5__title .title__name',{
        y: -50,
        opacity: 0,
        duration: 0, 
        }, "0")
    .to('#service-5 .service-5__title .title__desc',{
        y: 50,
        opacity: 0,
        duration: 0,
    }, "0")
    .to('#service-5 .service-5__content',{
        y: 150,
        opacity: 0,
        duration: 0,
    }, "0")
    .to('#service-5 .service-5__content .brand-service__infor',{
        x: 150,
        opacity: 0,
        duration: 0,
    }, "0")
    .to('#service-5 .service-5__content .brand-service__img',{
        opacity: 0,
        duration: 0,
    }, "0")
    .to('#service-5 .serSvg05 .setting01', {
        opacity: 0,
        duration: 0,
        rotate: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .setting02', {
        rotate: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .television', {
        scale: 3,
        duration: 0,
        opacity: 0,
        transformOrigin: 'center',
        ease: "linear",
    })
    .to('#service-5 .serSvg05 .tagOpenClose', {
        scale: 0,
        opacity: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .tagHtml', {
        scale: 0,
        opacity: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .desginer', {
        y: 120,
        opacity: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .desginImg', {
        scale: 0,
        opacity: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .book01', {
        scale: 3,
        y: 60,
        opacity: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .book02', {
        scale: 3,
        y: 60,
        opacity: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .instructor', {
        scale: 0,
        opacity: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
}

const aniClear = function(){
    clear();
    animationGsap
    .to('#service-5 .service-5__title .title__name',{
        y: 0,
        opacity: 1,
        duration: 0, 
        }, "0")
    .to('#service-5 .service-5__title .title__desc',{
        y: 0,
        opacity: 1,
        duration: 0,
    }, "0")
    .to('#service-5 .service-5__content',{
        y:0,
        opacity: 1,
        duration: 0,
    }, "0")
    .to('#service-5 .service-5__content .brand-service__infor',{
        x: 0,
        opacity: 1,
        duration: 0,
    }, "0")
    .to('#service-5 .service-5__content .brand-service__img',{
        opacity: 1,
        duration: 0,
    }, "0")
    .to('#service-5 .serSvg05 .setting01', {
        opacity: 1,
        duration: 0,
        rotate: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .setting02', {
        rotate: 0,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .television', {
        scale: 1,
        duration: 0,
        opacity: 1,
        transformOrigin: 'center',
        ease: "linear",
    })
    .to('#service-5 .serSvg05 .tagOpenClose', {
        scale: 1,
        opacity: 1,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .tagHtml', {
        scale: 1,
        opacity: 1,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .desginer', {
        y: 0,
        opacity: 1,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .desginImg', {
        scale: 1,
        opacity: 1,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .book01', {
        scale: 1,
        y: 0,
        opacity: 1,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .book02', {
        scale: 1,
        y: 0,
        opacity: 1,
        duration: 0,
        transformOrigin: 'center'
    },"0")
    .to('#service-5 .serSvg05 .instructor', {
        scale: 1,
        opacity: 1,
        duration: 0,
        transformOrigin: 'center'
    },"0")
}

export default{
    aniIn,
    aniOut,
    aniClear
}