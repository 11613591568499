declare var anime: any;
declare var ScrollTrigger: any;
declare var gsap: any;

const rpWidth = 1280;
const rpHeight = 540;

function autoBodyBrand() {
  let brand = document.querySelector('#brandPage');
  if (brand) {
    let headHtml = document.querySelector('head');
    headHtml.innerHTML += `<style>body, html{overflow-x:hidden;overflow-y:auto;height:auto}</style>`;

    let header = <HTMLElement>document.querySelector('.header');
    let bannerBrand = <HTMLElement>document.querySelector('.brand1-banner');
    let footer = <HTMLElement>document.getElementById('footer');

    if (bannerBrand && header) {
      // bannerBrand.style.paddingTop = header.clientHeight + "px";

      if (window.innerWidth >= rpWidth && window.innerHeight >= rpHeight) {
        bannerBrand.style.paddingTop = header.clientHeight + "px";
      }
      let timeOutRsWin: any;
      window.addEventListener('resize', function () {
        clearTimeout(timeOutRsWin);
        timeOutRsWin = this.setTimeout(function () {
          if (window.innerWidth >= rpWidth && window.innerHeight >= rpHeight) {
            bannerBrand.style.paddingTop = header.clientHeight + "px";
          }
          else {
            bannerBrand.style.paddingTop = "";
          }
        }, 300)
      })

      header.classList.add('scrollDown');

      let timeOutScroll: any;
      document.addEventListener('scroll', function () {
        clearTimeout(timeOutScroll);
        timeOutScroll = setTimeout(function () {
          if (window.scrollY > 100) {
            header.style.boxShadow = "0 3px 6px rgba(0,0,0,.16)";
          }
          else {
            header.style.boxShadow = "none";
          }
        }, 200)
      })
    }

    if (footer) {
      footer.classList.remove('relate');
      footer.classList.add('full');
    }
  }
}

function btnLineHandle() {
  let btns = document.querySelectorAll('.btn-see-more');
  btns.forEach(function (itemBtn) {
    itemBtn.innerHTML = `<span class="line"></span>` + itemBtn.innerHTML;
  })
}

function animateFrom(elem: any, direction: any) {
  direction = direction || 1;
  var x = 0,
    y = direction * 100;
  if (elem.classList.contains("gs_reveal_fromLeft")) {
    x = -100;
    y = 0;
  } else if (elem.classList.contains("gs_reveal_fromRight")) {
    x = 100;
    y = 0;
  }
  elem.style.transform = "translate(" + x + "px, " + y + "px)";
  elem.style.opacity = "0";
  gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
    duration: 1.25,
    x: 0,
    y: 0,
    autoAlpha: 1,
    ease: "expo",
    overwrite: "auto"
  });
}


function animateFrom1(elem: any) {
  var direction = 1;
  var x = 0,
    y = direction * 100;
  if (elem.classList.contains("gs_reveal_fromLeft")) {
    x = -100;
    y = 0;
  } else if (elem.classList.contains("gs_reveal_fromRight")) {
    x = 100;
    y = 0;
  }
  elem.style.transform = "translate(" + x + "px, " + y + "px)";
  elem.style.opacity = "0";
  gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
    duration: 1.25,
    x: 0,
    y: 0,
    autoAlpha: 1,
    ease: "expo",
    overwrite: "auto"
  });
}

function hide(elem: any) {
  gsap.set(elem, { autoAlpha: 0 });
}

function divideImage() {
  let cols = 2;
  let mainImg = document.querySelector('.brand1-img');

  if (!mainImg) {
    return;
  }

  // let images = [
  //     "./assets/images/page/brand/brand.png"
  // ];
  let images = document.querySelectorAll('.brand1-img > img')
  let current = 0;

  // let playing = false;

  // for (let i in images){
  //     new Image().src = images[i];
  // }

  let part = document.createElement('div');
  part.className = 'part';

  for (let col = 0; col < cols; col++) {
    let el = document.createElement('div');
    el.className = "section";
    let img = document.createElement('img');
    img.src = images[current].getAttribute('src');
    el.appendChild(img);
    // el.style.setProperty('--x', -100/cols*col + '%');
    part.appendChild(el);
  }

  mainImg.appendChild(part);

  if (document.querySelector('.brand1-img .part .section')) {
    // gsap.from('.brand1-img .part .section:nth-of-type(1)', {
    //     opacity: 0,
    //     scale: 0,
    //     transformOrigin: "center",
    //     duration: .9,
    //     scrollTrigger: {
    //         trigger: '.brand1-img .part .section',
    //         start: "top top",
    //         toggleActions: "restart none none none"
    //     }
    // })
    gsap.utils.toArray(".brand1-img .part .section").forEach(function (elem: any) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () { animateFrom1(elem) },
        onEnterBack: function () { animateFrom(elem, -1) },
        onLeave: function () { hide(elem) } // assure that the element is hidden when scrolled into view
      });
    });
  }

}

function animation() {
  if (document.querySelector('.brand1')) {
    // gsap.from('.hash1-right__item', {
    //     opacity: 0,
    //     scale: 0,
    //     transformOrigin: "center",
    //     delay: 1,
    //     stagger:{
    //         amount: .9
    //     },
    //     scrollTrigger: {
    //         trigger: '.hash1',
    //         toggleActions: "restart none none none"
    //     }
    // })

    gsap.utils.toArray(".hash1-right__item").forEach(function (elem: any) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () { animateFrom1(elem) },
        onEnterBack: function () { animateFrom(elem, -1) },
        onLeave: function () { hide(elem) } // assure that the element is hidden when scrolled into view
      });
    });
  }


  if (document.querySelector('.brand2')) {
    gsap.from('.brand2 .hash2', {
      opacity: 0,
      scale: 0,
      transformOrigin: "center",
      stagger: {
        amount: .9
      },
      scrollTrigger: {
        trigger: '.brand2 .hash2',
        toggleActions: "play none none none"
      }
    })
  }
}

export const brand = function () {
  autoBodyBrand();
  btnLineHandle();
  animation();
  divideImage();
}