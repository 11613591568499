declare var gsap : any;
declare var anime: any;
let animationGsap = gsap.timeline();

function clear(){
    animationGsap.clear();
}

function aniIn(){
    clear();
    
    animationGsap
        .to('#service-1 .service-1__title .title__name',{
            y: 0,
            opacity: 1,
            duration: .2,
        },)
        .to('#service-1 .service-1__title .title__desc',{
            y: 0,
            opacity: 1,
            duration: .2,
        },)
        .to('#service-1 .service-1__content',{
            y: 0,
            opacity: 1,
            duration: .2,
            
        },)
        .to('#service-1 .service-1__content .brand-service__infor',{
            x: 0,
            opacity: 1,
            duration: .2,
        },"<")

        .to('#service-1 .service-1__content .service-nen-1',{
            y: 0,
            opacity: 1,
            duration: .2,
        },)
        
        // .to('#service-1 .service-1__content .service-nen-2',{
        //     y: 131,
        //     opacity: 1,
        //     duration: .1,
        // },)
       
        .to('#service-1 .service-1__content .service-bang-1',{
            y: 0,
            scale: 1,
            opacity: 1,
            duration: .3,
            transformOrigin: "center"
        })
        .to('#service-1 .service-1__content .service-bang-2',{
            y: 0,
            scale: 1,
            opacity: 1,
            duration: .4,
        }, "<")
        .to('#service-1 .service-1__content .service-bang-3',{
            x: 0,
            scale: 1,
            opacity: 1,
            duration: .5,
        }, "<")
        .to('#service-1 .service-1__content .service-logo-1',{
            scale: 1,
            opacity: 1,
            duration: 1,
            transformOrigin: 'center'
        })

        .to('#service-1 .service-1__content .service-banhrang-0',{
            opacity: 1,
            duration: 0.3,
        }, "<")
        .to('#service-1 .service-1__content .service-banhrang-2',{
            rotate: -359,
            duration: 6,
            repeat: -1,
            transformOrigin: 'center',
            ease: "linear",
        })
        .to('#service-1 .service-1__content .service-banhrang-1',{
            rotate: 359,
            duration: 6,
            repeat: -1,
            transformOrigin: 'center',
            ease: "linear",
        }, "<0.3")


        .to('#service-1 .service-1__content .service-people-1',{
            x: 0,
            opacity: 1,
            duration: 0.2,
        }, "<")
        .to('#service-1 .service-1__content .box-people-1',{
            rotate: 360,
            scale: 1,
            opacity: 1,
            duration: 0.16,
        })

        .to('#service-1 .service-1__content .service-line-1',{
            height: 20,
            opacity: 1,
            duration: 3,
            repeat: -1,
        }, "<0.1")
        .to('#service-1 .service-1__content .service-line-2',{
            height: 29,
            opacity: 1,
            duration: 3,
            repeat: -1,
        }, "<")
        .to('#service-1 .service-1__content .service-line-3',{
            height: 35,
            opacity: 1,
            duration: 3,
            repeat: -1,
        }, "<")
        .to('#service-1 .service-1__content .service-line-4',{
            height: 41,
            opacity: 1,
            duration: 3,
            repeat: -1,
        }, "<")

        .to('#service-1 .service-1__content .service-line-0',{
            scale: 1,
            opacity: 1,
            duration: 0.1,
            transformOrigin: "left"
        }, "<")

        .to('#service-1 .service-1__content .service-people-2',{
            x: 0,
            scale: 1,
            rotate: 0,
            opacity: 1,
            duration: 0.3,
        }, "<")
       
        .to('#service-1 .service-1__content .service-bieudo-1',{
            height: 75,
            opacity: 1,
            duration: 0.2,
        }, "<")
        .to('#service-1 .service-1__content .service-bieudo-2',{
            height: 98,
            opacity: 1,
            duration: 0.2,
        }, "<0.1")
        .to('#service-1 .service-1__content .service-bieudo-3',{
            height: 80,
            opacity: 1,
            duration: 0.2,
        }, "<0.1")
        .to('#service-1 .service-1__content .service-bieudo-4',{
            height: 95,
            opacity: 1,
            duration: 0.2,
        }, "<0.1")
        .to('#service-1 .service-1__content .service-bieudo-5',{
            height: 118,
            opacity: 1,
            duration: 0.2,
        }, "<0.1")
        .to('#service-1 .service-1__content .service-bieudo-6',{
            height: 97,
            opacity: 1,
            duration: 0.2,
        }, "<0.1")
        .to('#service-1 .service-1__content .service-bieudo-7',{
            height: 113,
            opacity: 1,
            duration: 0.2,
        }, "<0.1")
        .to('#service-1 .service-1__content .service-bieudo-8',{
            height: 140,
            opacity: 1,
            duration: 0.2,
        }, "<0.1")
        .to('#service-1 .service-1__content .service-bieudo-9',{
            height: 177,
            opacity: 1,
            duration: 0.2,
        }, "<0.1")
        .to('#service-1 .service-1__content .service-bieudo-10',{
            height: 169,
            opacity: 1,
            duration: 0.2,
        }, "<0.1")

        .to('#service-1 .service-1__content .service-bieudo-11',{
            opacity: 1,
            duration: 2,
            scale: 1,
            transformOrigin: "left bottom"
        }, "-=1")

        // .to('#service-1 .service-1__content .service-cay-0',{
        //     x: -366,
        //     opacity: 1,
        //     duration: 0.1,
        // },)
        // .to('#service-1 .service-1__content .service-cay-1',{
        //     x: 0,
        //     opacity: 1,
        //     duration: 0.1,
        // },)
        // .to('#service-1 .service-1__content .service-cay-2',{
        //     x: -1748,
        //     opacity: 1,
        //     duration: 0.1,
        // },)
        // .to('#service-1 .service-1__content .service-cay-3',{
        //     x: -1854,
        //     opacity: 1,
        //     duration: 0.1,
        // },)
        
        // .to('#service-1 .service-1__content .service-may-1',{
        //     x: -1800,
        //     repeat: -1,
        //     ease: "slow",
        //     // opacity: 1,
        //     duration: 10,
        // },)
        // .to('#service-1 .service-1__content .service-may-2',{
        //     x: 1800,
        //     repeat: -1,
        //     ease: "slow",
        //     // opacity: 1,
        //     duration: 15,
        // }, "2")
    
        
        // .to('#about-1 .about-1__slide .swiper-slide',{
        //     y: 0 ,  opcaty: 1, duration: 1, stagger: 0.5
        // },)
        
        // .to('#about-1 .about-1__slide .ani-3',{
        //     y: 0 ,  opcaty: 1, duration: 1.2, 
        // },)
        // .to('#about-1 .about-1__slide .ani-4',{
        //     y: 0 ,  opcaty: 1, duration: 1.2, 
        // },)
    

}

function aniOut(){
    // gsap.to('.scrollText', {
    //     duration: 0, y: '120px', opacity: 0
    // })
    clear();
    
    animationGsap
        .to('#service-1 .service-1__title .title__name',{
            y: -50,
            opacity: 0,
            duration: 0, 
            },)
        .to('#service-1 .service-1__title .title__desc',{
            y: 50,
            opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content',{
            y: 1000,
            opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-nen-1',{
            y: 1000,
            opacity: 0,
            duration: 0,
        },)
        
        .to('#service-1 .service-1__content .service-bang-1',{
            y: 100,
            scale: 3,
            opacity: 0,
            duration: 0,
            transformOrigin: "center"
        },)

        .to('#service-1 .service-1__content .service-bang-2',{
            y: 100,
            scale: 3.5,
            opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bang-3',{
            x: 100,
            scale: 3,
            opacity: 0,
            duration: 0,
        },)

        .to('#service-1 .service-1__content .service-logo-1',{
            scale: 0,
            opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-people-1',{
            x: -500,
            opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .box-people-1',{
            rotate: 0,
            scale: 0,
            opacity: 0,
            duration: 0,
        },)

        .to('#service-1 .service-1__content .service-line-1',{
            height: 0,
            duration: 0,
            transformOrigin: "center",
            rotation: 180
        },)
        .to('#service-1 .service-1__content .service-line-2',{
            height: 0,
            duration: 0,
            transformOrigin: "center",
            rotation: 180
        },)
        .to('#service-1 .service-1__content .service-line-3',{
            height: 0,
            duration: 0,
            transformOrigin: "center",
            rotation: 180
        },)
        .to('#service-1 .service-1__content .service-line-4',{
            height: 0,
            duration: 0,
            transformOrigin: "center",
            rotation: 180
        },)

        .to('#service-1 .service-1__content .service-people-2',{
            x: 50,
            rotate: 360,
            scale: 0,
            opacity: 0,
            duration: 0,
            transformOrigin: "center"
        },)
        
        .to('#service-1 .service-1__content .service-bieudo-11',{
            scale: 0,
            opacity: 0,
            duration: 0,
            transformOrigin: "left bottom"
        },)

        .to('#service-1 .service-1__content .service-line-0',{
            scale: 0,
            opacity: 0,
            duration: 0,
        },)

        .to('#service-1 .service-1__content .service-bieudo-1',{
            height: 0,
            opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-2',{
            height: 0,
            opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-3',{
            height: 0,
            opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-4',{
            height: 0,
            opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-5',{
            height: 0,
            opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-6',{
            height: 0,
            opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-7',{
            height: 0,
            opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-8',{
            height: 0,
            opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-9',{
            height: 0,
            opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-10',{
            height: 0,
            opacity: 0,
            duration: 0,
        },)


        .to('#service-1 .service-1__content .service-banhrang-0',{
            opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-banhrang-1',{
            rotate: 0,
            duration: 0,
            transformOrigin: 'center'
        },"0")
        .to('#service-1 .service-1__content .service-banhrang-2',{
            rotate: 0,
            duration: 0,
            transformOrigin: 'center',
        },"0")

       
        

        .to('#service-1 .service-1__content .service-may-1',{
            x: 2000,
            // opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-may-2',{
            x: 2000,
            // ease: "linear",
            // opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .brand-service__infor',{
            x: 150,
            opacity: 0,
            duration: 0,
        }, "0")

    
        // .to('#service-1 .service-1__content .service-cay-0',{
        //     x: -500,
        //     opacity: 0,
        //     duration: 0,
        // },)
        // .to('#service-1 .service-1__content .service-cay-1',{
        //     x: -500,
        //     opacity: 0,
        //     duration: 0,
        // },)
        // .to('#service-1 .service-1__content .service-cay-2',{
        //     x: 2000,
        //     opacity: 0,
        //     duration: 0,
        // },)
        // .to('#service-1 .service-1__content .service-cay-3',{
        //     x: 2000,
        //     opacity: 0,
        //     duration: 0,
        // },)
}

function aniClear(){
    // gsap.to('.scrollText', {
    //     duration: 0, y: '120px', opacity: 0
    // })
    clear();
    
    animationGsap
        .to('#service-1 .service-1__title .title__name',{
            y: 0,
            opacity: 1,
            duration: 0, 
            },)
        .to('#service-1 .service-1__title .title__desc',{
            y: 0,
            opacity: 1,
            duration: 0,
        },)
        .to('#service-1 .service-1__content',{
            y: 0,
            opacity: 1,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-nen-1',{
            y: 0,
            opacity: 1,
            duration: 0,
        },)
        
        .to('#service-1 .service-1__content .service-bang-1',{
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 0,
            transformOrigin: "center"
        },)

        .to('#service-1 .service-1__content .service-bang-2',{
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bang-3',{
            x: 0,
            scale: 1,
            opacity: 1,
            duration: 0,
        },)

        .to('#service-1 .service-1__content .service-logo-1',{
            scale: 1,
            opacity: 1,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-people-1',{
            x: 0,
            opacity: 1,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .box-people-1',{
            rotate: 0,
            scale: 1,
            opacity: 1,
            duration: 0,
        },)

        .to('#service-1 .service-1__content .service-line-1',{
            height: 20,
            duration: 0,
            transformOrigin: "center",
            rotation: 0
        },)
        .to('#service-1 .service-1__content .service-line-2',{
            height: 29,
            duration: 0,
            transformOrigin: "center",
            rotation: 0
        },)
        .to('#service-1 .service-1__content .service-line-3',{
            height: 35,
            duration: 0,
            transformOrigin: "center",
            rotation: 0
        },)
        .to('#service-1 .service-1__content .service-line-4',{
            height: 41,
            duration: 0,
            transformOrigin: "center",
            rotation: 0
        },)

        .to('#service-1 .service-1__content .service-people-2',{
            x: 0,
            rotate: 0,
            scale: 1,
            opacity: 1,
            duration: 0,
            transformOrigin: "center"
        },)
        
        .to('#service-1 .service-1__content .service-bieudo-11',{
            scale: 1,
            opacity: 1,
            duration: 0,
            transformOrigin: "left bottom"
        },)

        .to('#service-1 .service-1__content .service-line-0',{
            scale: 1,
            opacity: 1,
            duration: 0,
        },)

        .to('#service-1 .service-1__content .service-bieudo-1',{
            height: "",
            opacity: 1,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-2',{
            height: "",
            opacity: 1,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-3',{
            height: "",
            opacity: 1,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-4',{
            height: "",
            opacity: 1,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-5',{
            height: "",
            opacity: 1,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-6',{
            height: "",
            opacity: 1,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-7',{
            height: "",
            opacity: 1,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-8',{
            height: "",
            opacity: 1,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-9',{
            height: "",
            opacity: 1,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-bieudo-10',{
            height: "",
            opacity: 1,
            duration: 0,
        },)


        .to('#service-1 .service-1__content .service-banhrang-0',{
            opacity: 1,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-banhrang-1',{
            rotate: 0,
            duration: 0,
            transformOrigin: 'center'
        },"0")
        .to('#service-1 .service-1__content .service-banhrang-2',{
            rotate: 0,
            duration: 0,
            transformOrigin: 'center',
        },"0")

        .to('#service-1 .service-1__content .service-may-1',{
            x: 0,
            // opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .service-may-2',{
            x: 0,
            // ease: "linear",
            // opacity: 0,
            duration: 0,
        },)
        .to('#service-1 .service-1__content .brand-service__infor',{
            x: 0,
            opacity: 1,
            duration: 0,
        }, "0")

    
        // .to('#service-1 .service-1__content .service-cay-0',{
        //     x: -500,
        //     opacity: 0,
        //     duration: 0,
        // },)
        // .to('#service-1 .service-1__content .service-cay-1',{
        //     x: -500,
        //     opacity: 0,
        //     duration: 0,
        // },)
        // .to('#service-1 .service-1__content .service-cay-2',{
        //     x: 2000,
        //     opacity: 0,
        //     duration: 0,
        // },)
        // .to('#service-1 .service-1__content .service-cay-3',{
        //     x: 2000,
        //     opacity: 0,
        //     duration: 0,
        // },)
}

export default{
    aniIn,
    aniOut,
    aniClear
}