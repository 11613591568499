declare var gsap: any;
// declare var Power2: any;
declare var Swiper: any;

let animationGsap = gsap.timeline();

// var sw = new Swiper("#brn-index-process .MyProcess", {
// 	direction: "vertical",
// 	slidesPerView: "auto",
// 	freeMode: true,
// 	scrollbar: {
// 		el: ".swiper-scrollbar",
// 	},
// 	mousewheel: true,
// 	speed: 1400,
// 	autoplay: {
// 		delay: 3500,
// 		disableOnInteraction: false,
// 	},
// });

// sw.autoplay.stop();

function clear() {
	animationGsap.clear();
}

function aniIn() {
	clear();

	animationGsap
		.to("#brn-index-process .brn-index-process__wrapper--process__title", {
			y: 0,
			opacity: 1,
			duration: 0.6,
			delay: 0.1,
		})
		.to(
			"#brn-index-process .items",
			{
				y: 0,
				opacity: 1,
				duration: 0.5,
				stagger: 0.2,
			},
			"<+=.2",
		)
		.to(
			"#brn-index-process .process_box",
			{
				y: 0,
				opacity: 1,
				duration: 0.6,
			},
			"<+=.3",
		)
		.to(
			"#brn-index-process .process_girl_1",
			{
				x: 0,
				opacity: 1,
				duration: 0.6,
			},
			"<+=.3",
		)
		.to(
			"#brn-index-process .process_board",
			{
				x: 0,
				opacity: 1,
				duration: 0.6,
				ease: "power1",
			},
			"<+=.6",
		)
		.to(
			"#brn-index-process .process_line",
			{
				opacity: 1,
				duration: 0.6,
			},
			"<",
		)
		.to(
			"#brn-index-process .process_girl_2",
			{
				x: 0,
				opacity: 1,
				duration: 0.7,
			},
			"<+=.3",
		)
		.to(
			"#brn-index-process .circle",
			{
				opacity: 1,
				duration: 0.7,
			},
			"<",
		);

	// sw.autoplay.start();
}

function aniOut() {
	clear();
	animationGsap
		.to("#brn-index-process .brn-index-process__wrapper--process__title", {
			y: -100,
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-process .items", {
			y: -100,
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-process .process_box", {
			y: 100,
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-process .process_girl_1", {
			x: -100,
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-process .process_board", {
			x: -100,
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-process .process_line", {
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-process .process_girl_2", {
			x: 100,
			opacity: 0,
			duration: 0,
		})
		.to("#brn-index-process .circle", {
			opacity: 0,
			duration: 0,
		});

    // sw.autoplay.stop();

}

function aniClear() {
	clear();
	animationGsap
		.to("#brn-index-process .brn-index-process__wrapper--process__title", {
			y: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-process .items", {
			y: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-process .process_box", {
			y: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-process .process_girl_1", {
			x: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-process .process_board", {
			x: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-process .process_line", {
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-process .process_girl_2", {
			x: 0,
			opacity: 1,
			duration: 0,
		})
		.to("#brn-index-process .circle", {
			opacity: 1,
			duration: 0,
		});
}

export default {
	aniIn,
	aniOut,
	aniClear,
};
