declare var gsap : any;
declare var Power2 : any;

let animationGsap = gsap.timeline();

function clear (){
    animationGsap.clear();
}

function aniIn(){
    clear();
    animationGsap.to('#brn-index-about .brn-index-about__wrapper--abusImg', {
        x: 0,
        opacity: 1,
        duration: 1.2,
        delay: .2
    }).to('#brn-index-about .about_girl', {
        x: 0,
        opacity: 1,
        duration: .8
    },"<+=.5").to('#brn-index-about .about_boy', {
        x: 0,
        opacity: 1,
        duration: .8
    },"<+=.5").to('#brn-index-about .about_infor', {
        x: 0,
        opacity: 1,
        duration: .4,
        stagger: 0.4
    },"<+=.6").to('#brn-index-about .brn-index-about__wrapper--abusInfor_btn', {
        opacity: 1,
        duration: .4,
        stagger: 0.4
    },"<").to('#brn-index-about .braney_star', {
        x: 0,
        opacity: 1,
        duration: .5,
        stagger: {each: .2, from: "end"},
    },"<").to('#brn-index-about .braney_logo', {
        y: 0,
        opacity: 1,
        duration: .8,
        ease: "power1",
        stagger: {each: .3, from: "end"},
    },"<+=.2").to('#brn-index-about .braney_text', {
        x: 0,
        opacity: 1,
        duration: .1,
        stagger: 0.1
    },"<-=.2").to('#brn-index-about .braney_text2', {
        x: 0,
        opacity: 1,
        duration: .1,
        stagger: 0.1
    },"<+=.4").to('#brn-index-about .braney_text3', {
        x: 0,
        opacity: 1,
        duration: .1,
        stagger: 0.1,
        repeat: Infinity
    },"<-=1")
}

function aniOut(){
    clear()
    animationGsap.to('#brn-index-about .brn-index-about__wrapper--abusImg', {
        x: -140,
        opacity: 0,
        duration: 0,
    }).to('#brn-index-about .about_girl', {
        x: -140,
        opacity: 0,
        duration: 0,
    }).to('#brn-index-about .about_boy', {
        x: 140,
        opacity: 0,
        duration: 0,
    }).to('#brn-index-about .braney_star', {
        x: 100,
        opacity: 0,
        duration: 0,
    }).to('#brn-index-about .about_infor', {
        x: 100,
        opacity: 0,
        duration: 0,
    }).to('#brn-index-about .brn-index-about__wrapper--abusInfor_btn', {
        opacity: 0,
        duration: 0,
    }).to('#brn-index-about .braney_logo', {
        y: 100,
        opacity: 0,
        duration: 0,
    }).to('#brn-index-about .braney_text', {
        x: -40,
        opacity: 0,
        duration: 0,
    }).to('#brn-index-about .braney_text2', {
        x: 40,
        opacity: 0,
        duration: 0,
    }).to('#brn-index-about .braney_text3', {
        x: -40,
        opacity: 0,
        duration: 0,
    })
}

function aniClear(){
    clear();
    animationGsap
    .to('#brn-index-about .brn-index-about__wrapper--abusImg', {
        x: 0,
        opacity: 1,
        duration: 0,
    }).to('#brn-index-about .about_girl', {
        x: 0,
        opacity: 1,
        duration: 0,
    }).to('#brn-index-about .about_boy', {
        x: 0,
        opacity: 1,
        duration: 0,
    }).to('#brn-index-about .braney_star', {
        x:0,
        opacity:1,
        duration: 0,
    }).to('#brn-index-about .about_infor', {
        x: 0,
        opacity:1,
        duration: 0,
    }).to('#brn-index-about .brn-index-about__wrapper--abusInfor_btn', {
        opacity:1,
        duration: 0,
    }).to('#brn-index-about .braney_logo', {
        y:0,
        opacity: 1,
        duration: 0,
    }).to('#brn-index-about .braney_text', {
        x: 0,
        opacity: 1,
        duration: 0,
    }).to('#brn-index-about .braney_text2', {
        x: 0,
        opacity: 1,
        duration: 0,
    }).to('#brn-index-about .braney_text3', {
        x: 0,
        opacity: 1,
        duration: 0,
    })
}

export default{
    aniIn,
    aniOut,
    aniClear
}