const rpWidth = 1280;
const rpHeight = 540;

function toggleMenubar(){
    var header = document.querySelector('.header');
    var body = document.querySelector('body');
    if (header){
        var headerLogo = header.querySelector('.header-logo');
        var toggleBtn = <HTMLElement>header.querySelector('.btn-toggle');
        if (toggleBtn){
            toggleBtn.addEventListener('click', function(){
                var lines = toggleBtn.querySelectorAll('.line');
                if (toggleBtn.classList.contains('focus')){
                    lines.forEach(function(line, index){
                        let lineE = <HTMLElement>line;
                        if (index === 0){
                            lineE.style.animation = `toggleMenuBarFirtsReverse .3s ease-in-out forwards`;
                        }
                        else if (index === lines.length - 1){
                            lineE.style.animation = `toggleMenuBarLastReverse .3s ease-in-out forwards`;
                        }
                        else{
                            lineE.style.animation = `toggleMenuBarReverse .3s ease-in-out forwards`;
                        }
                    })
                    
                    body.classList.remove('hiddenScroll');
                    header.classList.remove('show');
                }
                else{
                    lines.forEach(function(line, index){
                        let lineE = <HTMLElement>line;
                        if (index === 0){
                            lineE.style.animation = `toggleMenuBarFirts .3s ease-in-out forwards`;
                        }
                        else if (index === lines.length - 1){
                            lineE.style.animation = `toggleMenuBarLast .3s ease-in-out forwards`;
                        }
                        else{
                            lineE.style.animation = `toggleMenuBar .3s ease-in-out forwards`;
                        }
                    })
                    
                    body.classList.add('hiddenScroll');
                    header.classList.add('show');
                }

                headerLogo.classList.add('header-logo--bg');
                toggleBtn.classList.toggle('focus');
            })
        }
    }
}

function headerScroll(){
    let header = <HTMLElement> document.querySelector('.header');
    if (header){
        if (header.classList.contains('scrollDown')){
            document.addEventListener('scroll', function(){
                if (window.scrollY > 100){
                    header.style.boxShadow = "0 3px 6px rgba(0,0,0,.16)";
                }
            })
        }
    }
}

function scrollHeader(){
    let header = <HTMLElement>document.querySelector('.header');
    if (!header){
        return;
    }

    let hHeader = header.clientHeight;
    let timeoutScroll:any;

    // let banner = <HTMLElement>document.querySelector('.banner');
    // lấy section sau của header
    let nextHeader = <HTMLElement>header.nextElementSibling;

    // tạo sự kiện scroll
    // khi scrollY lớn hơn header clientHeight thì cho header có position fixed
    window.addEventListener('scroll', function(e){
        this.clearTimeout(timeoutScroll);   
        timeoutScroll = this.setTimeout(function(){
            if (window.innerWidth >= rpWidth && window.innerHeight >= rpHeight) {
                return;
            }
            if (window.scrollY > hHeader + 20){
                header.classList.add('scroll');

                if (nextHeader){
                    nextHeader.style.marginTop = hHeader + "px";
                }
            }
            else{
                header.classList.remove('scroll');
                if (nextHeader){
                    nextHeader.style.marginTop = "";
                }
            }
        }, 200)
    })
}

function activeNavItem(){
    let itemNav = document.querySelectorAll('.header-nav__item > a');
    itemNav.forEach(function(item){
        item.addEventListener('click', function(){
            item.classList.toggle('active');
        })
    })
}


export const header = function(){
    toggleMenubar();
    // headerScroll();
    scrollHeader();
    activeNavItem();
}