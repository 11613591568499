declare var gsap : any;
declare var Swiper: any;

let animationGsap = gsap.timeline();
var sw = new Swiper(".brn-index-project__wrapper--project .MyProjects", {
    spaceBetween: 80,
    slidesPerView: 3,
    navigation: {
      nextEl: ".brn-index-project__wrapper--project .swiper-button-next",
      prevEl: ".brn-index-project__wrapper--project .swiper-button-prev",
    },
    speed: 1400,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
    breakpoints: {
      // 0: {
      //   slidesPerView: 1,
      // },
      // 320: {
      //   slidesPerView: 1.25,
      // },
      // 540: {
      //   slidesPerView: 2,
      // },
      // 768: {
      //   slidesPerView: 3,
      // }

      0:{
        spaceBetween: 10,
        slidesPerView: 1,
      },
      461:{
        spaceBetween: 20,
        slidesPerView: 2,
      },
      993: {
        spaceBetween: 20,
        slidesPerView: 3,
      },
      1440: {
        spaceBetween: 80,
        slidesPerView: 3,
      }
    }

   
  })
sw.initialSlide = 0;
sw.autoplay.stop();

function clear (){
    animationGsap.clear();
}

function aniIn(){
    clear()
    animationGsap.to('#brn-index-project  .brn-index-project__wrapper--intro_title', {
        y: 0,
        opacity: 1,
        duration: .5,
    }).to('#brn-index-project  .brn-index-project__wrapper--intro_decs', {
        x: 0,
        opacity: 1,
        duration: .5,
    },"<.4").to('#brn-index-project  .brn-index-project__wrapper--project', {
        opacity: 1,
        duration: .3,
    },"<.2").to('#brn-index-project  .swiper-slide', {
        x: 0,
        opacity: 1,
        duration: .5,
        stagger: 0.2 
    },"<.4")
	sw.autoplay.start();
    
}

function aniOut(){
    clear()
    animationGsap.to('#brn-index-project  .brn-index-project__wrapper--intro_title', {
        y: -50,
        opacity: 0,
        duration: 0,
    }).to('#brn-index-project  .brn-index-project__wrapper--intro_decs', {
        x: 50,
        opacity: 0,
        duration: 0,
    }).to('#brn-index-project  .brn-index-project__wrapper--project', {
        opacity: 0,
        duration: 0,
    }).to('#brn-index-project  .swiper-slide', {
        x: 100,
        opacity: 0,
        duration: 0,
    })
	sw.slideTo(0);
    sw.autoplay.stop();
}

function aniClear(){
    clear()
    animationGsap.to('#brn-index-project  .brn-index-project__wrapper--intro_title', {
        y: 0,
        opacity: 1,
        duration: 0,
    }).to('#brn-index-project  .brn-index-project__wrapper--intro_decs', {
        x: 0,
        opacity: 1,
        duration: 0,
    }).to('#brn-index-project  .brn-index-project__wrapper--project', {
        opacity: 1,
        duration: 0,
    }).to('#brn-index-project  .swiper-slide', {
        x: 0,
        opacity: 1,
        duration: 0,
    })
}


export default{
    aniIn,
    aniOut,
    aniClear
}